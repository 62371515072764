import React from 'react';
import { useParams } from 'react-router-dom';
import cities from './BlogData';
import './BestPlacesBlog.css';

function BestPlacesBlog() {
  const { cityId } = useParams();

  const city = cities.find((city) => city.id === cityId);

  if (!city) {
    return <div className="blog-not-found">Blog not found</div>;
  }

  const blog = city.blogs.find((blog) => blog.title === 'Best Places to Visit in ' + city.name);

  if (!blog) {
    return <div className="blog-not-found">Blog not found</div>;
  }
  console.log(blog)

  const bestPlacesBlog = blog.sections.find((section) => section.title === 'Best Places in ' + city.name);

  if (!bestPlacesBlog) {
    return <div className="section-not-found">Section not found</div>;
  }

  const places = bestPlacesBlog.places;

  if (!places || places.length === 0) {
    return <div className="places-not-found">No places found</div>;
  }

  return (
    <div className="best-places-blog-container">
      <h2>{bestPlacesBlog.title}</h2>
      <div className="blog-section">
        <h3>{bestPlacesBlog.title1}</h3>
        <p>{bestPlacesBlog.intro}</p>
      </div>
      {places.map((place, index) => (
        <div className="place-content" key={index}>
          <h3>{place.name}</h3>
          <img src={place.image} alt={place.name} />
          <p>{place.description}</p>
          {place.thingsToDo && (
            <>
              <h5>Things to Do</h5>
              <ul>
                {place.thingsToDo.map((activity, index) => (
                  <li key={index}>{activity}</li>
                ))}
              </ul>
            </>
          )}
          {place.howToReach && (
            <>
              <h5>How to Reach</h5>
              <p>{place.howToReach}</p>
            </>
          )}
          {place.history && (
            <>
              <h5>History</h5>
              <p>{place.history}</p>
            </>
          )}
          {place.additionalDescription && (
            <>
              <h5>Additional Description</h5>
              <p>{place.additionalDescription}</p>
            </>
          )}
          {place.content && (
            <>
              <h5>Conclusion</h5>
              <p>{place.content}</p>
            </>
          )}
        </div>
      ))}
    </div>
  );
}

export default BestPlacesBlog;

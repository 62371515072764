import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import "./InternationalForm.css"; // Make sure to have a corresponding CSS file

export default function InternationTravelForum() {
  const [state, handleSubmit] = useForm("xleyjwkg");

  if (state.succeeded) {
    return (
      <p className="international-success-message">
        Thanks for submitting the form. Our team will contact you within 2
        working hours.
      </p>
    );
  }

  return (
    <div className="international-form-container">
      <form className="international-form" onSubmit={handleSubmit}>
        <h2 className="international-form-title">
          Customize Your International Trip
        </h2>

        <label htmlFor="name">Full Name</label>
        <input id="name" type="text" name="name" required />
        <ValidationError prefix="Name" field="name" errors={state.errors} />

        <label htmlFor="email">Email Address</label>
        <input id="email" type="email" name="email" required />
        <ValidationError prefix="Email" field="email" errors={state.errors} />

        <label htmlFor="phone">Contact Number</label>
        <input id="phone" type="tel" name="phone" required />
        <ValidationError prefix="Phone" field="phone" errors={state.errors} />

        <label htmlFor="city">Your City</label>
        <input id="city" type="text" name="city" required />
        <ValidationError prefix="City" field="city" errors={state.errors} />

        <label htmlFor="destination">Preferred Destination</label>
        <input id="destination" type="text" name="destination" required />
        <ValidationError
          prefix="Destination"
          field="destination"
          errors={state.errors}
        />

        <label htmlFor="travelDate">Preferred Travel Date</label>
        <input id="travelDate" type="date" name="travelDate" required />
        <ValidationError
          prefix="Travel Date"
          field="travelDate"
          errors={state.errors}
        />

        <label htmlFor="groupSize">Group Size</label>
        <input id="groupSize" type="number" name="groupSize" required />
        <ValidationError
          prefix="Group Size"
          field="groupSize"
          errors={state.errors}
        />

        <label htmlFor="budget">Budget Range</label>
        <input id="budget" type="text" name="budget" required />
        <ValidationError prefix="Budget" field="budget" errors={state.errors} />

        <label htmlFor="preferences">Special Preferences</label>
        <textarea id="preferences" name="preferences" required />
        <ValidationError
          prefix="Preferences"
          field="preferences"
          errors={state.errors}
        />

        <button
          className="international-form-btn"
          type="submit"
          disabled={state.submitting}
        >
          Get Customized Plan
        </button>
      </form>
    </div>
  );
}

import { Link } from "react-router-dom";
function Goa() {
  const images = [
    "https://i.ibb.co/kxBnNBR/ASMSHolidays-Honeymoon-Goa1.webp",
    "https://i.ibb.co/Sv3z5q3/ASMSHolidays-Honeymoon-Goa2.jpg",
    "https://i.ibb.co/ft7Srmd/ASMSHolidays-Honeymoon-Goa3.webp",
    "https://i.ibb.co/NxySmXg/ASMSHolidays-Honeymoon-Goa4.jpg",
  ];

  return (
    <>
      <div className="honeymoon-title-container">
        <h3 className="andaman-honeymoon-heading">Exquisite Goa Honeymoon: Surrendering to Love's Coastal Charms</h3>
      </div>
      <div className="honeymoon-box">
        <div className="photo-collage">
          <div class="row">
            <div class="col-md-12">
              <div className="left-column card-body">
                <h5 className="honeymoon-subheading">
                  Goa: A Magical Honeymoon Destination by the Arabian Sea
                </h5>
                <p className="honeymoon-subpara">
                  Experience an extraordinary honeymoon in the captivating land
                  of Goa. Immerse yourselves in the mesmerizing beauty of
                  sun-kissed beaches, swaying palm trees, and vibrant culture.
                  Indulge in romantic walks along the shoreline, bask in the
                  golden sunsets, and savor delectable seafood delicacies.
                  Explore the colonial architecture, visit ancient temples, and
                  dance to the rhythm of Goan music. Let the enchanting
                  landscapes and warm hospitality create cherished memories of
                  your love story. Goa is the perfect destination to celebrate
                  your union and embark on a memorable journey filled with
                  romance and bliss.
                </p>
                <Link to="/honeymoon-form">
           <button className="HoneymoonLocationbtn">Contact Us Now</button>
          </Link>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div className="right-column">
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                className="img-fluid"
                alt="goa"
              />
            ))}
          </div>
        </div>
      </div>
      <hr />
    </>
  );
}

export default Goa;

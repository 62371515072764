import React from 'react';
import { useParams } from 'react-router-dom';
import cities from './BlogData';
import './ThingsToTry.css';

function ThingsToTry() {
  const { cityId } = useParams();

  const city = cities.find((city) => city.id === cityId);

  if (!city) {
    return <div className="blog-not-found">City not found</div>;
  }

  const blog = city.blogs.find((blog) => blog.title === `Must Try Activities in ${city.name}`);

  if (!blog) {
    return <div className="blog-not-found">Blog not found</div>;
  }

  return (
    <div className="things-to-try-container">
      <h2>{blog.title}</h2>
      <p>{blog.intro}</p>
      {blog.activities.map((activity, index) => (
        <div className="activity-content" key={index}>
          <h3>{activity.name}</h3>
          <img src={activity.image} alt={activity.name} />
          <p>{activity.description}</p>
          <ul>
            <li>
              <strong>Duration:</strong> {activity.duration}
            </li>
            <li>
              <strong>Best Season:</strong> {activity.best_season}
            </li>
            <li>
              <strong>Required Equipment:</strong> {activity.required_equipment}
            </li>
            <li>
              <strong>Recommended For:</strong> {activity.recommended_for}
            </li>
          </ul>
        </div>
      ))}
    </div>
  );
}

export default ThingsToTry;

import { Link } from "react-router-dom";
import "./honeymoon.css";
export default function KashmirHoneymoon() {
  const images = [
    "https://i.ibb.co/Qp28F4F/ASMSHolidays-Honeymoon-kashmir1.jpg",
    "https://i.ibb.co/r7fPWM6/ASMSHolidays-Honeymoon-kashmir2.png",
    "https://i.ibb.co/LkfR0t7/ASMSHolidays-Honeymoon-kashmir3.jpg",
    "https://i.ibb.co/68MdrzJ/ASMSHolidays-Honeymoon-kashmir4.jpg",
  ];

  return (
    <>
      <div className="honeymoon-title-container">
        <h3 className="andaman-honeymoon-heading">
          "Captivating Kashmir Honeymoon Retreats: A Haven for Love and Romance"
        </h3>
      </div>
      <div className="honeymoon-box">
        <div className="photo-collage">
          <div class="row">
            <div class="col-md-12">
              <div className="left-column card-body">
                <h5 className="honeymoon-subheading">
                  "Enchanting Kashmir Honeymoon: Where Love Blossoms and
                  Memories Flourish Forever"
                </h5>
                <p className="honeymoon-subpara">
                  Immerse yourself in an extraordinary honeymoon experience
                  amidst the enchanting charms of Kashmir. Explore the timeless
                  beauty of this breathtaking destination, where every moment
                  becomes a cherished memory. From mesmerizing landscapes to
                  romantic hideaways, Kashmir offers an unparalleled setting for
                  your dream honeymoon. Let us curate an unforgettable
                  experience filled with love, tranquility, and everlasting joy.
                  Discover the allure of Kashmir and embark on a journey that
                  will leave you spellbound.
                </p>
                <Link to="/honeymoon-form">
           <button className="HoneymoonLocationbtn">Contact Us Now</button>
          </Link>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div className="right-column">
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                className="img-fluid"
                alt={`Image ${index + 1}`}
              />
            ))}
          </div>
        </div>
      </div>
      <hr />
    </>
  );
}

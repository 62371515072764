import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";

function Footer() {
  return (
    <footer className="footer bg-dark">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <div className="footer-section">
              <img
                src="https://i.ibb.co/PWZR9Mq/IMG-20230616-005812-removebg-preview.png"
                alt="Company Logo"
                className="footer-logo"
              />
              <p>Your Trusted Travel Partner for Unforgettable Adventures</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="footer-section p-3">
              <h4 className="mt-5">Contact Information</h4>
              <ul className="contact-list">
                <li>
                  <i className="fas fa-map-marker-alt"></i> Head Office: 
                   <br/>Tirupati Hight Appartment, Near 3rd Mile Stone Hotel Sofipure,
                   <br/> Meerut City, India, Uttar Pradesh </li>
                
                <li>
                  <i className="fas fa-map-marker-alt"></i> Branch office:
                   <br/> 47, MG Road, Junglighat, Near Samayapuram Mariamman Temple, 
                   <br/>  Port Blair Andaman-744103 </li>
                <li>
                  <i className="fas fa-envelope"></i> asmsholidays@gmail.com
                </li>
                <li>
                  <i className="fas fa-phone"></i> +91-9536834390
                </li>
                <li>
                  <i className="fas fa-phone"></i> +91-7906722481
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="footer-section p-3">
              <h4>Follow Us</h4>
              <ul className="social-media">
                <li>
                  <Link to="https://www.facebook.com/profile.php?id=100094517076440" target="_blank" rel="noopener noreferrer" >
                    <i
                      className="fab fa-facebook-f"
                      style={{ color: "#f7f7f7" }}
                    ></i>
                  </Link>
                </li>
                <li>
                  <Link to="https://twitter.com/AsmsHolidays" target="_blank" rel="noopener noreferrer">
                    <i
                      className="fab fa-twitter"
                      style={{ color: "#407ce2" }}
                    ></i>
                  </Link>
                </li>
                <li>
                  <Link to="https://www.instagram.com/asmsholidays/" target="_blank" rel="noopener noreferrer">
                    <i
                      className="fab fa-instagram"
                      style={{ color: "#eb058b" }}
                    ></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="footer-section p-3">
              <h4>Location</h4>
              <div className="map-container">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3488.4996278485355!2d77.70811131509161!3d29.031799982262008!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjnCsDAxJzU0LjUiTiA3N8KwNDInMzcuMSJF!5e0!3m2!1sen!2sin!4v1688127468627!5m2!1sen!2sin"
                  width="300"
                  height="150"
                  style={{ border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  title="Google Maps"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container m-3">
          <div className="row">
            <div className="col-lg-12 m-2">
              <p className="text-center">
                © {new Date().getFullYear()} A.S.M.S Holidays | All rights
                reserved | Developed By @Net Kreator
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

import React from 'react';
import { useParams, Link } from 'react-router-dom';
import cities from './BlogData';
import './KnowMore.css';

function KnowMore() {
  const { cityId } = useParams();
  const city = cities.find((city) => city.id === cityId);

  if (!city) {
    return <div className="city-not-found">City not found</div>;
  }

  return (
    <div className="know-more-container">
      <h2>Explore {city.name}</h2>
      <div className="know-more-grid">
        <div className="know-more-card">
          <div className="know-more-image">
            <img src={city.travelGuideImage} alt="Travel Guide" />
          </div>
          <div className="know-more-content">
            <h3 className="know-more-title">Travel Guide</h3>
            <Link to={`/know-more/${city.id}/travel-guide`} className="read-more-button">Read More</Link>
          </div>
        </div>
        <div className="know-more-card">
          <div className="know-more-image">
            <img src={city.bestPlacesImage} alt="Best Places" />
          </div>
          <div className="know-more-content">
            <h3 className="know-more-title">Best Places to Visit</h3>
            <Link to={`/know-more/${city.id}/best-places`} className="read-more-button">
              Read More
            </Link>
          </div>
        </div>
        <div className="know-more-card">
          <div className="know-more-image">
            <img src={city.thingsToTryImage} alt="Things to Try" />
          </div>
          <div className="know-more-content">
            <h3 className="know-more-title">Things to Try</h3>
            <Link to={`/know-more/${city.id}/things-to-try`} className="read-more-button">
              Read More
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default KnowMore;

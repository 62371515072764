import React from 'react';
import { Link } from 'react-router-dom';
import cities from './BlogData';
import './Blog.css';

function Blog() {
  return (
    <div className="blog-container">
      {cities.map((city, index) => (
        <div className="city-card" key={index}>
          <div className="image-container">
            <img src={city.image} alt={city.name}  />
          </div>
          <div className="city-info">
            <h3>{city.name}</h3>
            <p>{city.tagline}</p>
            <Link to={`/blog/knowMore/${city.id}`}><button>Explore More</button></Link>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Blog;

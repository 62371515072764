import './flight.css';
import { useForm, ValidationError } from '@formspree/react';

function FlightTrain() {
  const [state, handleSubmit] = useForm("xwkdpaer");

  if (state.succeeded) {
    return <p className="success-message">Thanks for submitting the form our team will connect you Within 2 Working Hours</p>;
  }

  return (
    <div className="hotels-container">
      <div className="hotel-banner">
        <img
          src="https://i.ibb.co/FxgtcFK/Blue-and-Green-Modern-Travel-Agent-Poster.png"
          className="flight-banner-img"
          alt="Hotel Banner"
        />
        <div className="flight-form">
          <form onSubmit={handleSubmit}>
            <h3 className="form-heading">
              Submit the Form and Expect a Response Within 2 Working Hours
            </h3>
            <div>
              <label htmlFor="name" className='hotels-label'>
                Name<span className="required-marker">*</span>:
              </label>
              <input
                id="name"
                type="text"
                name="name"
                required
              />
              <ValidationError
                prefix="name"
                field="name"
                errors={state.errors}
              />
            </div>
            <div>
              <label htmlFor="email" className='hotels-label'>
                Email<span className="required-marker">*</span>:
              </label>
              <input
                id="email"
                type="email"
                name="email"
                required
              />
              <ValidationError
                prefix="email"
                field="email"
                errors={state.errors}
              />
            </div>
            <div>
              <label htmlFor="phone" className='hotels-label'>
                Phone<span className="required-marker">*</span>:
              </label>
              <input
                id="phoneNum"
                type="tel"
                name="phoneNumber"
                required
              />
              <ValidationError
                prefix="phoneNum"
                field="phoneNum"
                errors={state.errors}
              />
            </div>
            <div>
              <label htmlFor="checkInDate" className='hotels-label'>
              Depart<span className="required-marker">*</span>:
              </label>
              <input
                type="date"
                id="checkInDate"
                name="TravelDate"
                required
              />
              <ValidationError
                prefix="checkInDate"
                field="checkInDate"
                errors={state.errors}
              />
            </div>
            <div>
              <label htmlFor="checkOutDate" className='hotels-label'>
                Return<span className="required-marker">*</span>:
              </label>
              <input
                type="date"
                id="checkOutDate"
                name="ReturnDate"
                required
              />
              <ValidationError
                prefix="checkOutDate"
                field="checkOutDate"
                errors={state.errors}
              />
            </div>
            <div>
              <label htmlFor="From" className='hotels-label'>
              From<span className="required-marker">*</span>:
              </label>
               <input
                type="text"
                id="From"
                name="From"
                required
              />
              <ValidationError
                prefix="checkOutDate"
                field="checkOutDate"
                errors={state.errors}
              />
            </div>
            <div>
              <label htmlFor="location" className='hotels-label'>
              Destination<span className="required-marker">*</span>:
              </label>
              <select
                id="location"
                name='Destinationlocation'
                required
              >
                <option value="">Select Location</option>
                <option value="Andaman">Andaman</option>
                <option value="Shimla">Shimla & Manali</option>
                <option value="Massrooi">Kashmir</option>
                <option value="Lakshadeep">Lakshadeep</option>
                <option value="Lakshadeep">Mumbai</option>
                <option value="Lakshadeep">Goa</option>
                <option value="Lakshadeep">Rajasthan</option>
                <option value="Lakshadeep">Kerala</option>
              </select>
              <ValidationError
                prefix="location"
                field="location"
                errors={state.errors}
              />
            </div>
            <div>
              <label htmlFor="numAdults" className='hotels-label'>
                Number of Persons (Adult)<span className="required-marker">*</span>:
              </label>
              <input
                type="number"
                id="numAdults"
                name="numAdults"
                required
              />
              <ValidationError
                prefix="numAdults"
                field="numAdults"
                errors={state.errors}
              />
            </div>
            <div>
              <label htmlFor="numChildren" className='hotels-label'>
                Number of Persons (Child)<span className="required-marker">*</span>:
              </label>
              <input
                type="number"
                id="numChildren"
                name="numChildren"
                required
              />
              <ValidationError
                prefix="numChildren"
                field="numChildren"
                errors={state.errors}
              />
            </div>
            <div>
              <label htmlFor="Mode" className='hotels-label'>
                Mode
              </label>
              <select
                id="Mode"
                name="Mode"
              >
                <option value="">Select Mode</option>
                <option value="1">Flight</option>
                <option value="2">Train</option>
               
              </select>
              <ValidationError
                prefix="hotelStarRating"
                field="hotelStarRating"
                errors={state.errors}
              />
            </div>
            <button className='flightButton' type="submit">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default FlightTrain;



{/* <div class="col-md-6">
      <div class="card-body">
        <p class="card-text01">At A.S.M.S Holidays, we understand the importance of personalized service when it comes to travel planning. That's why we offer a convenient and personalized approach to provide you with travel details and pricing. Simply reach out to us through a simple form or phone, email, and our dedicated team will be ready to assist you. 
        <br/>Share your travel preferences, including departure and arrival destinations, travel dates, and any specific requirements you may have. Our experts will curate a tailored selection of flight and train options that align with your needs. We'll then promptly deliver the comprehensive details, including pricing, directly to your phone and email. With this personalized service, you can make informed decisions and choose the best travel arrangements that suit your preferences and budget.
        <br/>
        <br/> Contact A.S.M.S Holidays today and let us take care of your travel details, ensuring a seamless and memorable journey.
        </p> */}
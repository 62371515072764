import "bootstrap/dist/js/bootstrap.js";
import React, { useState } from "react";
import "./subNavbar.css";
import { Link, Outlet } from "react-router-dom";

function SubNavbar() {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleLinkClick = () => {
    if (!isCollapsed) {
      setIsCollapsed(true);
    }
  };

  return (
    <div className="subnavbar">
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <button
            className={`navbar-toggler ${isCollapsed ? "collapsed" : ""}`}
            type="button"
            onClick={toggleCollapse}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`collapse navbar-collapse ${isCollapsed ? "" : "show"}`}
          >
            <ul className="navbar-nav mx-auto text-dark">
              <li className="nav-item">
                <Link
                  to="/"
                  className="nav-link"
                  activeClassName="active"
                  onClick={handleLinkClick}
                >
                  <i className="fas fa-home"> Home </i>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/inter-national-tour"
                  className="nav-link"
                  activeClassName="active"
                  onClick={handleLinkClick}
                >
                  <i className="fas fa-home"> Internation Tour </i>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/honeymoon"
                  className="nav-link"
                  activeClassName="active"
                  onClick={handleLinkClick}
                >
                  <i className="fas fa-heart"> Honeymoon </i>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/hotels"
                  className="nav-link"
                  activeClassName="active"
                  onClick={handleLinkClick}
                >
                  <i className="fas fa-hotel"> Hotels </i>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/rentals"
                  className="nav-link"
                  activeClassName="active"
                  onClick={handleLinkClick}
                >
                  <i className="fas fa-car-side"> Rentals </i>
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  to="/flight-train-booking"
                  className="nav-link"
                  activeClassName="active"
                  onClick={handleLinkClick}
                >
                  <i className="fas fa-plane fa-truck"> Flights & Trains </i>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/preWedding"
                  className="nav-link"
                  activeClassName="active"
                  onClick={handleLinkClick}
                >
                  <i className="fas fa-photo-video"> Pre & Post-Wedding </i>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/certificates"
                  className="nav-link"
                  activeClassName="active"
                  onClick={handleLinkClick}
                >
                  <i className="far fa-file-alt"> Certificates </i>
                </Link>
              </li>
              <li>
                <Link
                  to="/blog"
                  className="nav-link"
                  activeClassName="active"
                  onClick={handleLinkClick}
                >
                  <i class="fa-solid fa-newspaper"> Blog </i>
                </Link>
              </li>
            </ul>
            <hr className="hr-animation" />
          </div>
        </div>
      </nav>
      <Outlet />
    </div>
  );
}

export default SubNavbar;

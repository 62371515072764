import { Link } from 'react-router-dom';
import React from 'react';
import './ActivityCarousel.css';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';


const ActivityCarousel = ({ activityToDo }) => {
  const renderArrowPrev = (clickHandler) => (
    <button className="carousel-arrow carousel-arrow-left" onClick={clickHandler}>
      <i className="fa-solid fa-chevron-left"></i>
    </button>
  );

  const renderArrowNext = (clickHandler) => (
    <button className="carousel-arrow carousel-arrow-right" onClick={clickHandler}>
      <i className="fa-solid fa-chevron-right"></i>
    </button>
  );


  return (
    <div className="placescontainer">
      <h5 className="bestplaces bg-dark text-center">Things To Do</h5>
      <div className="bestplacescontaine">
        <Carousel
          showArrows={true}
          autoPlay={true}
          infiniteLoop={true}
          interval={4000}
          stopOnHover={true}
          showStatus={false}
          showThumbs={false}
          renderArrowPrev={renderArrowPrev}
          renderArrowNext={renderArrowNext}
        >
          {activityToDo.map((place, index) => (
            <div className="carousel-item" key={index}>
              <div className="card" style={{ minWidth: `60%` }}>
                <div className="row no-gutters">
                  <div className="col-md-6">
                    <img src={place.image} className="card-img" alt={place.name} />
                  </div>
                  <div className="col-md-6">
                    <div className="card-body">
                      <h5 className="cardtitle1">{place.name}</h5>
                      <p className="cardtext1">{place.description}</p>
                      <Link to="/call-now-form">
                        <button className="btn m-2">Book Now</button>
                        </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
      <hr />
    </div>
  );
};

export default ActivityCarousel;

import React from "react";
import { Link } from "react-router-dom";
import "./honeymoon.css";
import AndamanPrices from "./AndamanPrices";

function HoneymoonLocation() {
  const images = [
    "https://i.ibb.co/g3gLJPr/ASMSHolidays-honeymoon-Andaman1.jpg",
    "https://i.ibb.co/z738Ybs/ASMSHolidays-honeymoon-Andaman2.webp",
    "https://i.ibb.co/qsj37Bz/ASMSHolidays-Honeymoon-Andaman3.webp",
    "https://i.ibb.co/qWX7Nzw/ASMSHolidays-Honeymoon-Andaman4.png",
  ];

  return (
    <div>
      <div className="honeymoon-title-container">
        <h3 className="andaman-honeymoon-heading">Andaman's Blissful Honeymoon Escapes: Love's Perfect Haven</h3>
      </div>
      <div className="honeymoon-box">
        <div className="photo-collage">
        <div class="row">
          <div class="col-md-12">
          <div className="left-column card-body">
            <h5 className="honeymoon-subheading">
              "Unforgettable Andaman Honeymoon: Promising Everlasting Memories"
            </h5>
            <p className="honeymoon-subpara">
              Indulge in an unforgettable Andaman honeymoon experience, where we
              promise to create everlasting memories for you. Immerse yourself
              in the breathtaking beauty of Andaman's paradise, as you embark on
              a journey filled with love, romance, and bliss. From pristine
              beaches and turquoise waters to captivating sunsets and lush
              landscapes, every moment spent here will be etched in your heart
              forever. Trust us to deliver a honeymoon beyond your dreams, where
              cherished memories are crafted, and your love story unfolds in the
              most enchanting setting. Choose Andaman for your perfect getaway,
              and let us create an unforgettable honeymoon experience that will
              stay with you for a lifetime.
            </p>
          <Link to="/honeymoon-form">
           <button className="HoneymoonLocationbtn">Contact Us Now</button>
          </Link>
          </div>
</div>
</div>
</div>

          <div class="col-md-6">
          <div className="right-column">
            {images.map((image, index) => (
              <img key={index} src={image}  className='img-fluid' alt={``} />
              ))}
          </div>
          </div>
          </div>
        
      <hr />
      <AndamanPrices/>
          </div>
  );
}

export default HoneymoonLocation;

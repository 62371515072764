import { Link } from "react-router-dom";
import React from "react";

function RajasthanHoneymoon() {
  const images = [
    "https://i.ibb.co/KKSQNbD/ASMSHolidays-Honeymoon-Rajasthan1.webp",
    "https://i.ibb.co/vYSFrj5/ASMSHolidays-Honeymoon-Rajasthan2.jpg",
    "https://i.ibb.co/xGhySqL/ASMSHolidays-Honeymoon-Rajasthan3.webp",
    "https://i.ibb.co/QXsMw29/ASMSHolidays-Honeymoon-Rajasthan4.jpg",
  ];

  return (
    <>
      <div className="honeymoon-title-container">
        <h3 className="andaman-honeymoon-heading">
          "Rajasthan's Majestic Honeymoon: Unveiling Love's Royal Paradise"
        </h3>
      </div>
      <div className="honeymoon-box">
        <div className="photo-collage">
          <div class="row">
            <div class="col-md-12">
              <div className="left-column card-body">
                <h5 className="honeymoon-subheading">
                  "Embark on a Majestic Journey of Love and Splendor"{" "}
                </h5>
                <p className="honeymoon-subpara">
                  Embark on an enchanting honeymoon in the majestic realm of
                  Rajasthan. Experience the captivating beauty of its palaces,
                  forts, and vibrant culture. Explore the royal heritage,
                  indulge in exquisite cuisine, and immerse yourselves in the
                  warm hospitality of Rajasthan. Let the splendor of this
                  magnificent destination set the stage for an unforgettable
                  honeymoon, where love reigns supreme and cherished memories
                  are woven into the very fabric of your journey.
                </p>
                <Link to="/honeymoon-form">
           <button className="HoneymoonLocationbtn">Contact Us Now</button>
          </Link>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div className="right-column">
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                className="img-fluid"
                alt={`Image ${index + 1}`}
              />
            ))}
          </div>
        </div>
      </div>
      <hr />
    </>
  );
}

export default RajasthanHoneymoon;

import { Link } from "react-router-dom";

function ShimlaManaliHoneymoon() {
  const images = [
    "https://i.ibb.co/J7S5Q12/ASMSHolidays-Honeymoon-Shimla-Manali1.jpg",
    "https://i.ibb.co/HhHcsm8/ASMSHolidays-Honeymoon-Shimla-Manali2.jpg",
    "https://i.ibb.co/YfSbvQD/ASMSHolidays-Honeymoon-Shimla-Manali3.webp",
    "https://i.ibb.co/d5wFtQD/ASMSHolidays-Honeymoon-Shimla-Manali4.webp",
  ];

  return (
    <>
      <div className="honeymoon-title-container">
        <h3 className="andaman-honeymoon-heading">
          "Enchanting Shimla & Manali Honeymoon: Unveiling Nature's Majestic
          Love Story"
        </h3>
      </div>
      <div className="honeymoon-box">
        <div className="photo-collage">
          <div class="row">
            <div class="col-md-12">
              <div className="left-column card-body">
                <h5 className="honeymoon-subheading">
                  Shimla & Manali: Majestic Honeymoon Retreats Amidst Nature's
                  Embrace
                </h5>
                <p className="honeymoon-subpara">
                  Experience the enchantment of Shimla and Manali on your
                  majestic honeymoon getaway. Immerse yourselves in the
                  breathtaking beauty of these picturesque hill stations, where
                  love finds its perfect haven. Discover the serene landscapes,
                  snow-capped mountains, and cascading waterfalls that create a
                  romantic ambiance like no other. Indulge in warm hospitality,
                  savor delectable local cuisine, and create treasured memories
                  as you explore these charming destinations hand in hand. Let
                  Shimla and Manali be the backdrop to your love story,
                  unveiling a royal paradise where romance blossoms and
                  everlasting memories are made.
                </p>
                <Link to="/honeymoon-form">
           <button className="HoneymoonLocationbtn">Contact Us Now</button>
          </Link>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div className="right-column">
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                className="img-fluid"
                alt=""
              />
            ))}
          </div>
        </div>
      </div>
      <hr />
    </>
  );
}

export default ShimlaManaliHoneymoon;

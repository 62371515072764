import React from 'react';
import { useParams} from 'react-router-dom';
import cities from './BlogData';
import './CityTravelGuide.css';

function CityTravelGuide() {
  const { cityId } = useParams();
  const city = cities.find((city) => city.id === cityId);

  if (!city) {
    return <div className="city-not-found">City not found</div>;
  }

  const travelGuideBlog = city.blogs.find((blog) => blog.title === 'Travel Guide to ' + city.name);

  if (!travelGuideBlog) {
    return <div className="city-not-found">Travel guide not found for this city</div>;
  }


  return (
    <div className="city-travel-guide">
      <h2>Discover {city.name}: An Insider's Perspective</h2>
      <div className="blog-section">
        <h3 className="blog-title">{travelGuideBlog.title}</h3>
        {travelGuideBlog.sections.map((section) => (
          <div key={section.title}>
            <h4 className="section-title">{section.title}</h4>
            <img src={section.image} alt={section.title} className="section-image" />
            <p className="section-content">{section.content}</p>
          </div>
        ))}
      </div>

    </div>
  );
}

export default CityTravelGuide;

import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import './CallNow.css'; 

export default function CallNow() {
  const [state, handleSubmit] = useForm("xyyqzawq");

  if (state.succeeded) {
    return <p className="honeymoon-success-message">Thanks for submitting the form our team will connect you Within 2 Working Hours</p>;
  }

  return (
    <div className="Call-form-container">
      <form className="prewedding-form" onSubmit={handleSubmit}>
        <h2 className="honeymoonform-title">Request a Call</h2>

        <label htmlFor="name">Full Name</label>
        <input
          id="name"
          type="text"
          name="name"
          required
        />
        <ValidationError
          prefix="name"
          field="name"
          errors={state.errors}
        />
        <label htmlFor="phonenumber">Contact Number</label>
        <input
          id="phonenumber"
          type="tel"
          name="phonenumber"
          required
        />
        <ValidationError
          prefix="phonenumber"
          field="phonenumber"
          errors={state.errors}
        />
       
        <label htmlFor="email">Email Address</label>
        <input
          id="email"
          type="email"
          name="email"
          required
        />
        <ValidationError
          prefix="Email"
          field="email"
          errors={state.errors}
        />
        <label htmlFor="Your City">Your City</label>
        <input
          id="Your City"
          type="text"
          name="Your City"
          required
        />
        <ValidationError
          prefix="Your City"
          field="Your City"
          errors={state.errors}
        />

        <label htmlFor="location">Select a Loaction</label>
        <select
          id="location"
          name="location"
          required
        >
          <option value="">Select a Location</option>
          <option value="Andaman">Andaman</option>
          <option value="Kashmir">Kashmir</option>
          <option value="Shimla & Manali">Shimla & Manali</option>
          <option value="Goa">Goa</option>
          <option value="Mumbai">Mumbai</option>
          <option value="Lakshadeep">Lakshadeep</option>
          <option value="Rajasthan">Rajasthan</option>
          <option value="Kerala">Kerala</option>
        </select>
        <ValidationError
          prefix="location"
          field="location"
          errors={state.errors}
        />

        <label htmlFor="date">Select Date</label>
        <input
          id="date"
          type="Date"
          name="date"
          required
        />
        <ValidationError
          prefix="date"
          field="date"
          errors={state.errors}
        />
        <label htmlFor="numAdults">
                Number of Persons (Adult)<span className="required-marker">*</span>:
              </label>
              <input
                type="number"
                id="numAdults"
                name="numAdults"
                required
              />
              <ValidationError
                prefix="numAdults"
                field="numAdults"
                errors={state.errors}
              />
              <label htmlFor="numChildren">
                Number of Persons (Child)<span className="required-marker">*</span>:
              </label>
              <input
                type="number"
                id="numChildren"
                name="numChildren"
                required
              />
              <ValidationError
                prefix="numChildren"
                field="numChildren"
                errors={state.errors}
              />
              <label htmlFor="hotelStarRating">
                Hotel Star Rating:
              </label>
              <select
                id="hotelStarRating"
                name="hotelStarRating"
              >
                <option value="">Select Star Rating</option>
                <option value="1">1 Star</option>
                <option value="2">2 Stars</option>
                <option value="3">3 Stars</option>
                <option value="4">4 Stars</option>
                <option value="5">5 Stars</option>
              </select>
              <ValidationError
                prefix="hotelStarRating"
                field="hotelStarRating"
                errors={state.errors}
              />

        <label htmlFor="message">Additional Comments</label>
        <textarea
          id="message"
          name="message"
          required
        />
        <ValidationError
          prefix="Message"
          field="message"
          errors={state.errors}
        />

        <button  className="honeymoonFormBtn"  type="submit" disabled={state.submitting}>
          Book Now
        </button>
      </form>
    </div>
  );
}

import './certificates.css';

function Certificates() {
  return (
    <>
      <div className="certificates-container">
        <div className="img-container">
          <img
            src="https://i.ibb.co/Smtqm2B/57810638559.png"
            className="certificates-banner"
            alt="Certificates Banner"
          />
          <p className="top-container-text">Why Choose Us?</p>
          <p className="top-container-text">
            Leave your worries <br /> Think about relaxing days ahead
          </p>
        </div>
        <h5 className="placesheadline bg-light text-dark p-2 m-2">
          Welcome to A.S.M.S Holidays - Your Gateway to Exquisite Destinations.
        </h5>
        <div className="card1 bg-dark rounded shadow-lg">
          <div className="row g-0">
            <div className="col-md-4">
              <img
                src="https://i.ibb.co/PWZR9Mq/IMG-20230616-005812-removebg-preview.png"
                className="card-img8"
                alt="ASMS Holidays"
                loading="lazy"
              />
            </div>
            <div className="col-md-8">
              <div className="card-body">
                <p className="card-text-1">
                  ASMS Holidays is your trusted partner in creating unforgettable travel experiences. With a passion
                  for exploration and a commitment to excellence, we curate personalized itineraries to suit your
                  unique preferences. From handpicked accommodations to seamless logistics, we ensure every aspect
                  of your journey is meticulously planned. Join us and embark on a remarkable adventure with ASMS Holidays.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="certificated-image-container p-4">
          <div className="card-3">
            <img
              src="https://i.ibb.co/cy4cN6K/imgonline-com-ua-twotoone-y6-Zda-KI4-JSR5.jpg"
              className="certificated-img1 img-fluid"
              alt="Udhyam Registration Certificate"
            />
            <div className="card-body">
              <p className="card-text5">
                ASMS Holidays, the epitome of unforgettable getaways, proudly showcases our Udhyam Registration
                Certificate. This prestigious accreditation ensures meticulous planning, exclusive perks, and unmatched
                travel experiences. With ASMS Holidays, you can trust in our commitment to excellence and customer
                satisfaction. Our Udhyam Registration Certificate guarantees the highest standards of quality, safety,
                and transparency, providing you with peace of mind throughout your journey. Embark on extraordinary
                adventures with ASMS Holidays' Udhyam Registration Certificate. Experience meticulously planned trips,
                exclusive perks, and unforgettable memories. Start planning your dream getaway today and discover the
                trusted difference with ASMS Holidays.
              </p>
            </div>
          </div>
          <div className="card card-3">
            <img
              src="https://i.ibb.co/Lnndwb7/AA0904231067847-RC25042023-3-1.jpg"
              className="certificated-img1 img-fluid"
              alt="GST Compliance Certificate"
            />
            <div className="card-body">
              <p className="card-text5">
                At ASMS Holidays, we prioritize transparency and compliance, which is why we proudly showcase our GST
                compliance. When you choose ASMS Holidays for your travel needs, you can trust that our pricing is
                transparent, inclusive of GST, and adheres to all legal requirements. Our commitment to GST compliance
                ensures a seamless booking process, allowing you to plan your dream vacation with peace of mind. Say
                goodbye to hidden charges and unexpected surprises, and embrace the clarity and trust that comes with
                booking with a GST-compliant travel partner. Experience exceptional service and enjoy the peace of mind
                that comes from knowing your vacation is in the hands of a trusted GST-compliant travel provider. Start
                your journey with ASMS Holidays today and discover a new level of confidence and transparency in travel
                planning.
              </p>
            </div>
          </div>
        </div>
        <div className="card1 bg-dark rounded shadow-lg p-auto m-3">
          <div className="row g-0">
            <div className="col-md-12">
              <div className="card-body">
                <div className='card-text-1'>Our Vision</div>
                <p className="card-text-1">
                  Unleashing the magic of travel, ASMS Holidays is dedicated to curating extraordinary journeys that
                  awaken the senses and ignite the spirit of adventure. With a passion for innovation and an unwavering
                  commitment to personalized service, we strive to redefine the travel experience, crafting unforgettable
                  moments that surpass expectations. Embracing sustainability and cultural immersion, we aspire to create
                  a profound impact on destinations and foster a deep connection between travelers and the world, leaving
                  an indelible mark on the hearts and minds of all who embark on our extraordinary voyages.
                </p>
              </div>
            </div>
                     </div>
        </div>
      </div>
    </>
  );
}

export default Certificates;

import React from 'react';
import "./Whatsapp.css";
import { Link } from 'react-router-dom';


function Whatsapp() {
  const phoneNumber = "+917455947877"; // replace with your phone number
  const message = "Hello, Share me more the details for ........ Location"; // replace with your message
  const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

  return (
    <div>
      <Link to={url} target="_blank" rel="noopener noreferrer">
        <img src='https://media.tenor.com/Spdlu7aT88AAAAAj/wp.gif' className='whatsappLogo' style={{maxWidth:'40px'}} alt='WhatsApp Logo'/>
      </Link>
    </div>
  );
}

export default Whatsapp;

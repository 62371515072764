import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./prices.css";
import Swiper from "swiper";

export default function AndamanPrices() {
  useEffect(() => {
    // Initialize Swiper when the component mounts
    new Swiper(".mySwiper", {
      // Add any Swiper options you need here
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  }, []);

  return (

    <>
      <swiper-container
        class="mySwiper"
        navigation="true"
        pagination="true"
        effect="flip"
        grab-cursor="false"
      >
        <swiper-slide>
          <div class="card border-danger mb-5">
            <div class="card-header bg-transparent border-danger">
              3 Nights & 4 Days Package
            </div>
            <div class="card-body text-danger">
              <h5 class="card-title">Swaraj Dweep & Shaheed Deep</h5>
              <p class="card-text">(Havclock Island & Neil Island)</p>
              <p class="card-text">
                Starting at 9999 per person (Min 2 Persons)
              </p>
            </div>
            <div class="card-footer bg-transparent border-danger">
              <Link to="/honeymoon-form">
                <button className="HoneymoonLocationbtn">Contact Now</button>
              </Link>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="card border-danger mb-5">
            <div class="card-header bg-transparent border-danger">
              3 Nights & 4 Days Package
            </div>
            <div class="card-body text-danger">
              <h5 class="card-title">
                Swaraj Dweep, Rose Island & North Bay Island
              </h5>
              <p class="card-text">
                (Havclock Island, Rose Island & North Bay Island)
              </p>
              <p class="card-text">
                Starting at 7999 per person (Min 2 Persons)
              </p>
            </div>
            <div class="card-footer bg-transparent border-danger">
              <Link to="/honeymoon-form">
                <button className="HoneymoonLocationbtn">Contact Now</button>
              </Link>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="card border-danger mb-5">
            <div class="card-header bg-transparent border-danger">
              3 Nights & 4 Days Package
            </div>
            <div class="card-body text-danger">
              <h5 class="card-title">Swaraj Dweep & Jolly Buoy Island</h5>
              <p class="card-text">(Havclock Island & Jolly Buoy Island)</p>
              <p class="card-text">
                Starting at 9499 per person (Min 2 Persons)
              </p>
            </div>
            <div class="card-footer bg-transparent border-danger">
              <Link to="/honeymoon-form">
                <button className="HoneymoonLocationbtn">Contact Now</button>
              </Link>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="card border-danger mb-5">
            <div class="card-header bg-transparent border-danger">
              4 Nights & 5 Days Package
            </div>
            <div class="card-body text-danger">
              <h5 class="card-title">Swaraj Dweep & Shaheed Deep</h5>
              <p class="card-text">(Havclock Island & Neil Island)</p>
              <p class="card-text">
                Starting at 12899 per person (Min 2 Persons)
              </p>
            </div>
            <div class="card-footer bg-transparent border-danger">
              <Link to="/honeymoon-form">
                <button className="HoneymoonLocationbtn">Contact Now</button>
              </Link>
            </div>
          </div>
        </swiper-slide>

        <swiper-slide>
          <div class="card border-danger mb-5">
            <div class="card-header bg-transparent border-danger">
              4 Nights & 5 Days Package
            </div>
            <div class="card-body text-danger">
              <h5 class="card-title">
                Swaraj Dweep, Rose Island & North Bay Island
              </h5>
              <p class="card-text">
                (Havclock Island, Rose Island & North Bay Island)
              </p>
              <p class="card-text">
                Starting at 10999 per person (Min 2 Persons)
              </p>
            </div>
            <div class="card-footer bg-transparent border-danger">
              <Link to="/honeymoon-form">
                <button className="HoneymoonLocationbtn">Contact Now</button>
              </Link>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="card border-danger mb-5">
            <div class="card-header bg-transparent border-danger">
              4 Nights & 5 Days Package
            </div>
            <div class="card-body text-danger">
              <h5 class="card-title">
                Swaraj Dweep, Rose Island, North Bay Island & Baratang
              </h5>
              <p class="card-text">
                (Havclock Island, Rose Island, North Bay Island & Baratang)
              </p>
              <p class="card-text">
                Starting at 12499 per person (Min 2 Persons)
              </p>
            </div>
            <div class="card-footer bg-transparent border-danger">
              <Link to="/honeymoon-form">
                <button className="HoneymoonLocationbtn">Contact Now</button>
              </Link>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="card border-danger mb-5">
            <div class="card-header bg-transparent border-danger">
              4 Nights & 5 Days Package
            </div>
            <div class="card-body text-danger">
              <h5 class="card-title">
                Swaraj Dweep, Rose Island, North Bay Island & Chidiyatapu
              </h5>
              <p class="card-text">
                (Havelock Island, Rose Island, North Bay Island & Chidiyatapu)
              </p>
              <p class="card-text">
                Starting at 9499 per person (Min 2 Persons)
              </p>
            </div>
            <div class="card-footer bg-transparent border-danger">
              <Link to="/honeymoon-form">
                <button className="HoneymoonLocationbtn">Contact Now</button>
              </Link>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="card border-danger mb-5">
            <div class="card-header bg-transparent border-danger">
              5 Nights & 6 Days Package
            </div>
            <div class="card-body text-danger">
              <h5 class="card-title">
                Swaraj Dweep, Shaheed Dweep & Mount Manipur
              </h5>
              <p class="card-text">
                (Havelock Island, Neil Island & Mount Harriet)
              </p>
              <p class="card-text">
                Starting at 15999 per person (Min 2 Persons)
              </p>
            </div>
            <div class="card-footer bg-transparent border-danger">
              <Link to="/honeymoon-form">
                <button className="HoneymoonLocationbtn">Contact Now</button>
              </Link>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="card border-danger mb-5">
            <div class="card-header bg-transparent border-danger">
              5 Nights & 6 Days Package
            </div>
            <div class="card-body text-danger">
              <h5 class="card-title">
                Swaraj Dweep, Shaheed Dweep, Rose Island, North Bay Island &
                Chidiyatapu
              </h5>
              <p class="card-text">
                (Havelock Island, Neil Island, Rose Island, North Bay Island &
                Chidiyatapu)
              </p>
              <p class="card-text">
                Starting at 14999 per person (Min 2 Persons)
              </p>
            </div>
            <div class="card-footer bg-transparent border-danger">
              <Link to="/honeymoon-form">
                <button className="HoneymoonLocationbtn">Contact Now</button>
              </Link>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="card border-danger mb-5">
            <div class="card-header bg-transparent border-danger">
              5 Nights & 6 Days Package
            </div>
            <div class="card-body text-danger">
              <h5 class="card-title">
                Swaraj Dweep, Shaheed Dweep, Ross Island & Wandoor
              </h5>
              <p class="card-text">
                (Havelock Island, Neil Island, Ross Island & Wandoor)
              </p>
              <p class="card-text">
                Starting at 14199 per person (Min 2 Persons)
              </p>
            </div>
            <div class="card-footer bg-transparent border-danger">
              <Link to="/honeymoon-form">
                <button className="HoneymoonLocationbtn">Contact Now</button>
              </Link>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="card border-danger mb-5">
            <div class="card-header bg-transparent border-danger">
              6 Nights & 7 Days Package
            </div>
            <div class="card-body text-danger">
              <h5 class="card-title">
                Swaraj Dweep, Shaheed Dweep, Port Blair & Baratang
              </h5>
              <p class="card-text">
                (Havelock Island, Neil Island, Port Blair & Baratang)
              </p>
              <p class="card-text">
                Starting at 17999 per person (Min 2 Persons)
              </p>
            </div>
            <div class="card-footer bg-transparent border-danger">
              <Link to="/honeymoon-form">
                <button className="HoneymoonLocationbtn">Contact Now</button>
              </Link>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="card border-danger mb-5">
            <div class="card-header bg-transparent border-danger">
              6 Nights & 7 Days Package
            </div>
            <div class="card-body text-danger">
              <h5 class="card-title">
                Swaraj Dweep, Shaheed Dweep, Rose Island, Chidiyatapu & Baratang
              </h5>
              <p class="card-text">
                (Havelock Island, Neil Island, Rose Island, Chidiyatapu &
                Baratang)
              </p>
              <p class="card-text">
                Starting at 18999 per person (Min 2 Persons)
              </p>
            </div>
            <div class="card-footer bg-transparent border-danger">
              <Link to="/honeymoon-form">
                <button className="HoneymoonLocationbtn">Contact Now</button>
              </Link>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="card border-danger mb-5">
            <div class="card-header bg-transparent border-danger">
              7 Nights & 8 Days Package
            </div>
            <div class="card-body text-danger">
              <h5 class="card-title">
                Swaraj Dweep, Shaheed Dweep, Wandoor, Rose Island, Chidiyatapu &
                Baratang
              </h5>
              <p class="card-text">
                (Havelock Island, Neil Island, Rose Island, Wandoor, Chidiyatapu
                & Baratang)
              </p>
              <p class="card-text">
                Starting at 20499 per person (Min 2 Persons)
              </p>
            </div>
            <div class="card-footer bg-transparent border-danger">
              <Link to="/honeymoon-form">
                <button className="HoneymoonLocationbtn">Contact Now</button>
              </Link>
            </div>
          </div>
        </swiper-slide>
      </swiper-container>
      <div className="Andaman-honeymoon-footer">
          <p>
            <i class="fa-solid fa-xmark" style={{ color: `#f20202` }}></i> Does
            not include flight ticket
          </p>
          <p>
            <i
              class="fa-sharp fa-solid fa-check"
              style={{ color: `#27f40b` }}
            ></i>{" "}
            Include tour guide
          </p>
          <p>
            <i
              class="fa-sharp fa-solid fa-check"
              style={{ color: `#27f40b` }}
            ></i>{" "}
            Include hotel and cruise ticket
          </p>
          <p>
            <i
              class="fa-sharp fa-solid fa-check"
              style={{ color: `#27f40b` }}
            ></i>{" "}
            Include candle light dinner
          </p>
        </div>
    </>
  );
}

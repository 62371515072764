import { Link } from 'react-router-dom';
import React from 'react';
import './feries.css';

function Ferries() {
  const ferryData = [
    {
      name: 'Makruzz',
      image: 'https://i.ibb.co/Lg5TmgY/ASMSHolidays-Anadaman-Makruzz.jpg',
      description: 'Experience luxury and comfort on the high-speed Makruzz ferry, offering a seamless journey to your destination.'
    },
    {
      name: 'Green Ocean',
      image: 'https://i.ibb.co/bsWXFqB/ASMSHolidays-Andaman-Green-Ocean.jpg',
      description: 'Travel in style with Green Ocean, a state-of-the-art ferry known for its eco-friendly operations and scenic voyages.'
    },
    {
      name: 'Nautika',
      image: 'https://i.ibb.co/fk86x6y/ASMSHolidays-Andaman-Nautika.jpg',
      description: 'Embark on an adventure with Nautika, a modern and spacious ferry that ensures a safe and enjoyable sailing experience.'
    },
    {
      name: 'ITT Majestic',
      image: 'https://i.ibb.co/1MxzWLL/ASMSHolidays-Anadaman-IIT-Majestic.jpg',
      description: 'Discover luxury and grandeur on board ITT Majestic, a majestic ferry offering top-notch amenities and impeccable service.'
    }
  ];

  return (
    <div className="ferries-container">
      <h5 className="latestblogs">Ferry Services</h5>
      <div className="ferry-grid">
        {ferryData.map((ferry, index) => (
          <div className="ferry-card" key={index}>
            <div className="ferry-image">
              <img src={ferry.image} alt={ferry.name} />
            </div>
            <div className="ferry-content">
              <h4 className="ferry-name">{ferry.name}</h4>
              <p className="ferry-description">{ferry.description}</p>
              <Link to="/call-now-form">
              <a href="#" className="btn">Book Now</a>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Ferries;

import { Link } from "react-router-dom";
import React from "react";

export default function LakshyadeepHoneymoon() {
  const images = [
    "https://i.ibb.co/cgznPHN/ASMSHolidays-Honeymoon-Lakshadeep1.jpg",
    "https://i.ibb.co/t3yNbSm/ASMSHolidays-Honeymoon-Lakshadeep2.webp",
    "https://i.ibb.co/SPdFXM6/ASMSHolidays-Honeymoon-Lakshadeep3.jpg",
    "https://i.ibb.co/hgLmy72/ASMSHolidays-Honeymoon-Lakshadeep4.jpg",
  ];

  return (
    <>
      <div className="honeymoon-title-container">
        <h3 className="andaman-honeymoon-heading">
          "Exquisite Lakshadweep Honeymoon: Unveiling Love's Serene Paradise"
        </h3>
      </div>

      <div className="honeymoon-box">
        <div className="photo-collage">
          <div class="row">
            <div class="col-md-12">
              <div className="left-column card-body">
                <h5 className="honeymoon-subheading">
                  "Discover Pure Bliss: Lakshadweep's Exquisite Honeymoon
                  Escapes"
                </h5>
                <p className="honeymoon-subpara">
                  Escape to Lakshadweep, a pristine paradise perfect for
                  creating unforgettable honeymoon memories. Immerse yourself in
                  its untouched beauty, from turquoise lagoons to secluded
                  beaches. Discover serenity, romance, and thrilling adventures
                  together. Indulge in delectable seafood, embrace warm
                  hospitality, and let the magic of Lakshadweep weave
                  unforgettable moments for a lifetime.
                </p>
                <Link to="/honeymoon-form">
           <button className="HoneymoonLocationbtn">Contact Us Now</button>
          </Link>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div className="right-column">
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                className="img-fluid"
                alt={`Image ${index + 1}`}
              />
            ))}
          </div>
        </div>
      </div>
      <hr />
    </>
  );
}

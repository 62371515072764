import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./honeymoon.css";
import HoneymoonLocation from "./HoneymoonLocation";
import KashmirHoneymoon from "./KashmirHoneymoon";
import LakshyadeepHoneymoon from "./LakshyadeepHoneymoon";
import RajasthanHoneymoon from "./RajasthanHoneymoon";
import ShimlaManaliHoneymoon from "./ShimlaManaliHoneymoon";
import Goa from "./Goa";
import Kerala from "./Kerala";
import { Link } from "react-router-dom";

const CarouselComponent = () => {
  const carouselItems = [
    {
      id: 1,
      src: "https://i.ibb.co/3f53nbr/ASMSHoneymoon-Andaman.jpg",
      caption: "Andaman",
    },
    {
      id: 2,
      src: "https://i.ibb.co/wwCVt4r/ASMSHolidays-honeymoon2.jpg",
      caption: "Kashmir",
    },
    {
      id: 3,
      src: "https://i.ibb.co/wgdySS9/ASMSHolidays-Honeymoon-Lakshadeep.jpg",
      caption: "Lakshadeep",
    },
    {
      id: 4,
      src: "https://i.ibb.co/727jbCb/ASMSHolidays-Honeymoon-Rajasthan.jpg",
      caption: "Rajasthan",
    },
    {
      id: 5,
      src: "https://i.ibb.co/sg2B47M/ASMSHolidays-honeymoon-shimla-manali.jpg",
      caption: "Shimla & Manali",
    },
    {
      id: 6,
      src: "https://i.ibb.co/Df6wwWJ/ASMSHolidays-Honeymoon-Goa.jpg",
      caption: "Goa",
    },
    {
      id: 7,
      src: "https://i.ibb.co/1bJ6gdf/ASMSHolidays-Honeymoon-kerala.jpg",
      caption: "Kerala",
    },
    // Add more carousel items as needed
  ];

  return (
    <Carousel
      showThumbs={true}
      autoPlay
      infiniteLoop 
    >
      {carouselItems.map((item) => (
        <div key={item.id} className="carousel-itemm">
          <div className="cards">
            <img src={item.src} alt={item.caption} />
            <div className="">
              <h5 className="cardtitle">{item.caption}</h5>
            </div>
          </div>
        </div>
      ))}
    </Carousel>
  );
};

export default function Honeymoon() {
  return (
    <div className="container">
      <h2 className="honeymoon-heading">
        Romantic Honeymoon Destinations for Unforgettable Travel Experiences...
        <i class="fa-solid fa-heart fa-beat" style={{ color: `#e60f0f` }}></i>
      </h2>
      <div className="container">

      <div className="row">
        <div className="col-md-6 col order-sm-1">
          <div>

          <h3 className="slide-in-left">
            Celebrate Your Love with a Dreamy Honeymoon
          </h3>
          <p className="fade-in">
            Congratulations on your wedding! Discover the perfect honeymoon
            getaway with us. Our travel experts will plan a personalized
            itinerary for your dream honeymoon. Choose from romantic beach
            retreats, adventurous mountain escapes, and cultural explorations.
            Explore curated packages and create unforgettable memories together.
          </p>
          <Link to="/honeymoon-form">
           <button className="HoneymoonLocationbtn">Contact Us Now</button>
          </Link>
          </div>
        </div>
        <div className="col-md-6 col  order-sm-2">
          <div>
          <CarouselComponent />
          </div>
        </div>
      </div>
      </div>
      <hr/>
      <HoneymoonLocation/>
      <hr/>
      <KashmirHoneymoon/>
      <LakshyadeepHoneymoon/>
      <RajasthanHoneymoon/>
      <ShimlaManaliHoneymoon/>
      <Goa/>
      <Kerala/>
    </div>
  );
}

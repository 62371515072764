import { Link } from 'react-router-dom';
import  './preWedding.css';

const PreWedding = () => {
  const backgroundImage = "https://i.ibb.co/sCfHJ7W/Andaman-Pre-Wedding3.jpg";

  return (
    <div className="pre-wedding-container">
      <div
        className="background-image"
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
        loading="lazy"
      >
        <div className="content">
          <h2>Celebrate Love's Journey</h2>
          <p>Experience the magic of pre and post-wedding shoots</p>
          <Link to={"/pre-wedding-form"}>
          <button className='preweddingbtn'>Book Now</button>
          </Link>
        </div>
      </div>
      <div class="row g-0"> 
   <div class="col-md-6">
   <div className="carousel-container">
        <h3>Discover the Perfect Moments</h3>
        <p>Capture the essence of your love story with our exceptional pre and post-wedding shoot services. Our team of talented photographers and videographers will create stunning visuals that beautifully portray your unique journey together.</p>
        <p>You can showcase your love in various locations and breathtaking settings. From romantic beaches to picturesque landscapes, we'll help you capture unforgettable memories that will last a lifetime.</p>
        <p>Immerse yourself in the joy and excitement of your pre-wedding days, or commemorate the blissful moments after your wedding celebrations. Let us be a part of your special moments and create timeless keepsakes that will be cherished forever.</p>
      </div>
   </div>
   <div class="col-md-6 m-auto">
   <div id="carouselExampleFade" class="carousel slide carousel-fade" data-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src="https://i.ibb.co/Cs3bDJr/andaman-parasailing2.jpg" class="d-block w-100" alt="..." loading="lazy"/>
    </div>
    <div class="carousel-item">
      <img src="https://i.ibb.co/SVwcc05/Andaman-Pre-Wedding1.jpg" class="d-block w-100" alt="..." loading="lazy"/>
    </div>
    <div class="carousel-item">
      <img src="https://i.ibb.co/nPHyyYz/Andaman-Pre-Wedding6.jpg" class="d-block w-100" alt="..." loading="lazy"/>
    </div>
    <div class="carousel-item">
      <img src="https://i.ibb.co/8zjcmZQ/Andaman-Pre-Wedding5.jpg" class="d-block w-100" alt="..." loading="lazy"/>
    </div>
    <div class="carousel-item">
      <img src="https://i.ibb.co/1GQHhsp/Andaman-Pre-Wedding4.jpg" class="d-block w-100" alt="..." loading="lazy"/>
    </div>

  </div>
  <a class="carousel-control-prev" href="#carouselExampleFade" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselExampleFade" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>
   </div>
 </div>




    </div>
  );
};

export default PreWedding;

import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import './RentalsForm.css'; // Import the CSS file

export default function RentalsForm() {
  const [state, handleSubmit] = useForm("mdorkpbv");

  if (state.succeeded) {
    return <p className="success-message">Thanks for submitting the form our team will connect you Within 2 Working Hours</p>;
  }

  return (
    <div className="rental-form-container">
      <form className="rentals-form" onSubmit={handleSubmit}>
        <h2 className="form-title">Book a Rental Vehicle</h2>

        <label htmlFor="name">Full Name</label>
        <input
          id="name"
          type="text"
          name="name"
          required
        />
        <ValidationError
          prefix="name"
          field="name"
          errors={state.errors}
        />
        <label htmlFor="phonenumber">Contact Number</label>
        <input
          id="phonenumber"
          type="tel"
          name="phonenumber"
          required
        />
        <ValidationError
          prefix="phonenumber"
          field="phonenumber"
          errors={state.errors}
        />
       
        <label htmlFor="email">Email Address</label>
        <input
          id="email"
          type="email"
          name="email"
          required
        />
        <ValidationError
          prefix="Email"
          field="email"
          errors={state.errors}
        />

        <label htmlFor="vehicle">Select a Vehicle</label>
        <select
          id="vehicle"
          name="vehicle"
          required
        >
          <option value="">Select a vehicle</option>
          <option value="Honda Activa">Honda Activa</option>
          <option value="TVS Jupiter">TVS Jupiter</option>
          <option value="Royal Enfield">Royal Enfield</option>
          <option value="Maruti Suzuki Dzire">Maruti Suzuki Dzire</option>
          <option value="Hundai Xcent">Hundai Xcent</option>
          <option value="Mahindra Xylo">Mahindra Xylo</option>
          <option value="Maruti Ertiga">Maruti Ertiga</option>
          <option value="Mahindra Scorpio">Mahindra Scorpio</option>
          <option value="Toyota Innova">Toyota Innova</option>
          <option value="Tata Winger(13 Seater)">Tata Winger(13 Seater)</option>
          <option value="Traveller (13 Seater)">Traveller (13 Seater)</option>
          <option value="Traveller (17 Seater)">Traveller (17 Seater)</option>
          <option value="Traveller (26 Seater)">Traveller (26 Seater)</option>
          {/* Add more vehicle options here */}
        </select>
        <ValidationError
          prefix="Vehicle"
          field="vehicle"
          errors={state.errors}
        />

        <label htmlFor="pickupLocation">Pickup Location</label>
        <input
          id="pickupLocation"
          type="text"
          name="pickupLocation"
          required
        />
        <ValidationError
          prefix="Pickup Location"
          field="pickupLocation"
          errors={state.errors}
        />
        <label htmlFor="pickupDate">Pickup Date</label>
        <input
          id="pickupDate"
          type="Date"
          name="pickupDate"
          required
        />
        <ValidationError
          prefix="Pickup Date"
          field="pickupDate"
          errors={state.errors}
        />

        <label htmlFor="message">Additional Comments</label>
        <textarea
          id="message"
          name="message"
          required
        />
        <ValidationError
          prefix="Message"
          field="message"
          errors={state.errors}
        />

        <button type="submit" disabled={state.submitting}>
          Book Now
        </button>
      </form>
    </div>
  );
}

import React, { useState } from 'react';
import './navbar.css';

function Navbar() {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleLinkClick = () => {
    if (!isCollapsed) {
      setIsCollapsed(true);
    }
  };

  return (
    <>
      <nav className={`navbar1 navbar navbar-expand-lg ${isCollapsed ? '' : 'show'}`}>
        <a className="navbar-brand" to="/">
          <img
            className="logo"
            alt="ASMS-Logo"
            loading="lazy"
            src="https://i.ibb.co/PWZR9Mq/IMG-20230616-005812-removebg-preview.png"
          />
        </a>

        <button
          className={`navbar-toggler ${isCollapsed ? 'collapsed' : ''}`}
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className={`navbar-collapse ${isCollapsed ? 'collapse' : 'show'}`}>
          <ul className="navbar-nav ml-auto">
            <li className="nav-item active">
              <div className="number-container d-flex">
                <img
                  src="https://icon-library.com/images/call-now-icon-png/call-now-icon-png-7.jpg"
                  className="call-now"
                  alt="call-now"
                />
                <div>
                  <a className="nav-link nav01" onClick={handleLinkClick}>9536834390 </a>
                  <a className="nav-link nav01" onClick={handleLinkClick}>7906722481 </a>
                </div>
              </div>
            </li>
            
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
import { Link } from 'react-router-dom';
import React from 'react';
import './cruise.css';

function Cruise() {
  const cruiseData = [
    {
      title: 'Dinner Cruise',
      image: 'https://i.ibb.co/hVbr2h7/ASMSHolidays-Dinner-Cruise-Andaman.jpg',
      description: 'Experience a romantic dinner cruise while enjoying the breathtaking beauty of Andaman\'s coastline.'
    },
    {
      title: 'Big Game Fishing',
      image: 'https://i.ibb.co/gMVZkmP/ASMSHolidays-Big-Game-Fishing.jpg',
      description: 'Embark on an exciting fishing adventure and test your skills against the mesmerizing marine life of Andaman.'
    },
    {
      title: 'Sunset Cruise',
      image: 'https://i.ibb.co/2nvsdnm/ASMSHolidays-Sunset-Cruise.jpg',
      description: 'Witness the magical hues of the Andaman sunset while cruising through the tranquil waters.'
    },
    {
      title: 'Cruise Nightlife',
      image: 'https://i.ibb.co/LJnVjbg/ASMSHolidays-Anadaman-Night-life.jpg',
      description: 'Experience the vibrant nightlife of Andaman on a cruise, with music, dance, and entertainment.'
    },
    {
      title: 'Private Charter',
      image: 'https://i.ibb.co/kGFshjY/ASMSHolidays-Anadaman-Private-Charter.jpg',
      description: 'Enjoy a luxurious and exclusive private charter experience tailored to your preferences.'
    }
  ];

  return (
    <div className="cruise-container">
      <hr/>
      <h3 className="placesheadline">"Cruising the Enchanting Andaman: Unforgettable Adventures on the Island Seas"</h3>
      <div className="card-container">
        {cruiseData.map((cruise, index) => (
          <div className="card-flip" key={index}>
            <div className="card-front">
              <img src={cruise.image} className="card-img" alt={cruise.title} />
              <h5 className="card-title">{cruise.title}</h5>
            </div>
            <div className="card-back bg-dark">
              <img src='https://i.ibb.co/PWZR9Mq/IMG-20230616-005812-removebg-preview.png' className="logo" alt="Logo" />
              <p className="card-description text-light">{cruise.description}</p>
              <Link to="/call-now-form">
                        <button className="btn m-2">Book Now</button>
                        </Link>
            </div>
          </div>
        ))}
      </div>
      <hr/>
    </div>
  );
}

export default Cruise;

import React from "react";
import { Link } from "react-router-dom";
import "./Internation.css";
import destinations from "./destinations";

function Internation() {
  return (
    <div className="international-container">
      <h1>International Travel Destinations</h1>
      <div className="destination-container">
        {destinations.map((destination) => (
          <div className="destination-card" key={destination.id}>
            <img src={destination.image} alt={destination.name} />
            <h2>{destination.name}</h2>
            <div className="buttons">
              <Link to="/contact-form">
                <button className="upload-button">Book Now</button>
              </Link>
              <Link to={`/explore-more/${destination.id}`}>
                <button className="explore-button">Explore More</button>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Internation;

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./Components/Navbar/Navbar";
import SubNavbar from "./Components/Navbar/SubNavbar";
import Certificates from "./Components/certificates/Certificates";
import Hotels from "./Components/hotels/Hotels";
import Main from "./Components/main/Main";
import Rentals from "./Components/rentals/Rentals";
import Honeymoon from "./Components/honeymoon/Honeymoon";
import Footer from "./Components/footer/Footer";
import PreWedding from "./Components/preWedding/PreWedding";
import FlightTrain from "./Components/flight/FlightTrain";
import WhatsAppChat from "./Components/whatsapp/Whatsapp";
import Blog from "./Components/blog/Blog";
import KnowMore from "./Components/blog/KnowMore";
import CityTravelGuide from "./Components/blog/CityTravelGuide";
import BestPlacesBlog from "./Components/blog/BestPlacesBlog";
import ThingsToTry from "./Components/blog/ThingsToTry";
import RentalsForm from "./Components/rentals/RentalsForm";
import PreWeddingForm from "./Components/preWedding/PreWeddingForm";
import HoneymoonContactForm from "./Components/honeymoon/HoneymoonContactForm";
import CallNow from "./Components/main/CallNow";
import Internation from "./Components/internation/Internation";
import InternationTravelForum from "./Components/internation/InternationTravelForum";
import InternationalTripDetails from "./Components/internation/InternationalTripDetails";

function App() {
  return (
    <Router>
      <div className="navbar-box">
        <Navbar />
        <SubNavbar />
      </div>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/certificates" element={<Certificates />} />
        <Route path="/hotels" element={<Hotels />} />
        <Route path="/rentals" element={<Rentals />} />
        <Route path="/honeymoon" element={<Honeymoon />} />
        <Route path="/preWedding" element={<PreWedding />} />
        <Route path="/flight-train-booking" element={<FlightTrain />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/knowMore/:cityId" element={<KnowMore />} />
        <Route
          path="/know-more/:cityId/travel-guide"
          element={<CityTravelGuide />}
        />
        <Route
          exact
          path="/know-more/:cityId/best-places"
          element={<BestPlacesBlog />}
        />
        <Route
          path="/know-more/:cityId/things-to-try"
          element={<ThingsToTry />}
        />
        <Route path="/rental-service" element={<RentalsForm />} />
        <Route path="/pre-wedding-form" element={<PreWeddingForm />} />
        <Route path="/honeymoon-form" element={<HoneymoonContactForm />} />
        <Route path="/call-now-form" element={<CallNow />} />
        <Route path="/inter-national-tour" element={<Internation />} />
        <Route path="/contact-form" element={<InternationTravelForum />} />
        <Route
          path="/explore-more/:id"
          element={<InternationalTripDetails />}
        />
      </Routes>
      <WhatsAppChat />
      <Footer />
    </Router>
  );
}

export default App;

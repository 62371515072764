import "./rentals.css";
import { Link } from "react-router-dom";

function Rentals() {
  return (
    <>
      <div className="rental-container">
        <img
          src="https://i.ibb.co/yFc7hrp/Automotive-Banner-Landscape.png"
          className="rental-banner"
          alt="Rentals-Service-Banner"
        />
        <p className="rental-text">Best CAR/BIKE Rentals Service</p>
      </div>
      <h3 className="rentalheadline bg-dark text-light p-3">
        Double the Fun: Choose from Two/Four Vehicle Rental Choices
      </h3>
      <div class="row mx-auto p-2 row-cols-1 row-cols-md-4">
        <div class="col-s-12 col-mb-3">
          <div class="card">
            <img
              src="https://i.ibb.co/CzrcTcH/ASMSHolidays-Rental-Service-Honda-Active.jpg"
              class="card-img-top"
              alt="Honda-Actica-Rental-Service"
            />
            <div class="card-body">
              <h5 class="card-title">Honda Activa</h5>
              <Link to="/rental-service">
                <button className="btn btn-dark btn-lg btn-block p-1 mx-auto">
                  Book Now
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div class="col-s-12 col-mb-3">
          <div class="card">
            <img
              src="https://i.ibb.co/0fNJg9R/oie-34s-MHy-WV7-Xw-G.jpg"
              class="card-img-top"
              alt="TVS-Jupiter-Rental-Service"
            />
            <div class="card-body">
              <h5 class="card-title">TVS Jupiter</h5>
              <Link to="/rental-service">
                <button className="btn btn-dark btn-lg btn-block p-1 mx-auto">
                  Book Now
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div class="col-s-12 col-mb-3">
          <div class="card">
            <img
              src="https://i.ibb.co/L5VYRZm/ASMSHolidays-Rental-Service-Bajaj-Averanger.jpg"
              class="card-img-top"
              alt="Bajaj-Avenger-Rental-Service"
            />
            <div class="card-body">
              <h5 class="card-title">Bajaj Avenger</h5>
              <Link to="/rental-service">
                <button className="btn btn-dark btn-lg btn-block p-1 mx-auto">
                  Book Now
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div class="col-s-12 col-mb-3">
          <div class="card">
            <img
              src="https://i.ibb.co/LpDGRt3/ASMSHolidays-Rental-Service-Royal-Enfield.jpg"
              class="card-img-top"
              alt="Royal-Enfideld-Rental-Sevice"
            />
            <div class="card-body">
              <h5 class="card-title">Royal Enfield</h5>
              <Link to="/rental-service">
                <button className="btn btn-dark btn-lg btn-block p-1 mx-auto">
                  Book Now
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <h3 className="rentalheadline bg-dark text-light p-3">
        Drive in Style: Choose from Our Selection of Sedan Rentals
      </h3>
      <div class="row mx-auto p-2 row-cols-1 row-cols-md-4">
        <div class="col-md-4">
          <div class="card">
            <img
              src="https://i.ibb.co/XyJhySt/ASMSHolidays-Rental-Service-Maruti-Suzaki-Dzire.webp"
              class="card-img-top"
              alt="Maruti-Suzuki-Dzire-Rental-Service"
            />
            <div class="card-body">
              <h5 class="card-title">Maruti Suzuki Dzire</h5>
              <Link to="/rental-service">
                <button className="btn btn-dark btn-lg btn-block p-1 mx-auto">
                  Book Now
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card">
            <img
              src="https://i.ibb.co/Q6pvLzV/ASMSHolidays-Rental-Serivce-Hyundai-xcent.webp"
              class="card-img-top"
              alt="Hundai-Xcent-Rental-Service"
            />
            <div class="card-body">
              <h5 class="card-title">Hundai Xcent</h5>
              <Link to="/rental-service">
                <button className="btn btn-dark btn-lg btn-block p-1 mx-auto">
                  Book Now
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <h3 className="rentalheadline bg-dark text-light p-3">
        Experience Power and Capability with Our SUV Rentals
      </h3>
      <div class="row mx-auto p-2 row-cols-1 row-cols-md-4">
        <div class="col-md-3">
          <div class="card">
            <img
              src="https://i.ibb.co/jRPMWL5/ASMSHolidays-Rental-Serivce-Mahindra-xylo.webp"
              class="card-img-top"
              alt="Mahindra-Xylo-Rental-Sevice"
            />
            <div class="card-body">
              <h5 class="card-title">Mahindra Xylo</h5>
              <Link to="/rental-service">
                <button className="btn btn-dark btn-lg btn-block p-1 mx-auto">
                  Book Now
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card">
            <img
              src="https://i.ibb.co/LpyvbHr/ASMSHolidays-Rental-Serivce-Ertiga.jpg"
              class="card-img-top"
              alt="Maruti-Ertiga-Rental-Service"
            />
            <div class="card-body">
              <h5 class="card-title">Maruti Ertiga</h5>
              <Link to="/rental-service">
                <button className="btn btn-dark btn-lg btn-block p-1 mx-auto">
                  Book Now
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card">
            <img
              src="https://i.ibb.co/m6d8Jmn/ASMSHolidays-Rental-Serivce-Scorpio.webp"
              class="card-img-top"
              alt="Mahindra-Scorpio-Rental-Service"
            />
            <div class="card-body">
              <h5 class="card-title">Mahindra Scorpio</h5>
              <Link to="/rental-service">
                <button className="btn btn-dark btn-lg btn-block p-1 mx-auto">
                  Book Now
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <h3 className="rentalheadline bg-dark text-light p-3">
        Experience Luxury Redefined: Rent the Finest in Class and Style
      </h3>
      <div class="row mx-auto p-2 row-cols-1 row-cols-md-4">
        <div class="col-md-4">
          <div class="card">
            <img
              src="https://i.ibb.co/Pm0zCBd/ASMS-Holidays-Rental-Service-Innova.png"
              class="img-fluid"
              alt="Toyota-Innova-Rental-Sevice"
            />
            <div class="card-body">
              <h5 class="card-title">Toyota Innova</h5>
              <Link to="/rental-service">
                <button className="btn btn-dark btn-lg btn-block p-1 mx-auto">
                  Book Now
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <h3 className="rentalheadline bg-dark text-light p-3">
        Travel Together with Ease: Rent Vehicles for Group Excursions
      </h3>
      <div class="row mx-auto p-2 row-cols-1 row-cols-md-4">
        <div class="col mb-4">
          <div class="card">
            <img
              src="https://i.ibb.co/CMpqgg2/ASMSHolidays-Rental-Service-Tata-Winger.jpg"
              class="card-img-top"
              alt="Tata Winger(13 Seater)-Rental-Service"
            />
            <div class="card-body">
              <h5 class="card-title">Tata Winger(13 Seater)</h5>
              <Link to="/rental-service">
                <button className="btn btn-dark btn-lg btn-block p-1 mx-auto">
                  Book Now
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div class="col mb-4">
          <div class="card">
            <img
              src="https://i.ibb.co/gmHs8rF/ASMSHolidays-Rental-Service-Tempo-traveller.jpg"
              class="card-img-top"
              alt="Traveller (13 Seater)-Rental-Service"
            />
            <div class="card-body">
              <h5 class="card-title">Traveller (13 Seater)</h5>
              <Link to="/rental-service">
                <button className="btn btn-dark btn-lg btn-block p-1 mx-auto">
                  Book Now
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div class="col mb-4">
          <div class="card">
            <img
              src="https://i.ibb.co/QH1V9nn/ASMSHolidays-Rental-Service-traveller.jpg"
              class="card-img-top"
              alt="Traveller (17 Seater)-Rental-Service"
            />
            <div class="card-body">
              <h5 class="card-title">Traveller (17 Seater)</h5>
              <Link to="/rental-service">
                <button className="btn btn-dark btn-lg btn-block p-1 mx-auto">
                  Book Now
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div class="col mb-4">
          <div class="card">
            <img
              src="https://i.ibb.co/K6dMmM8/ASMSHolidays-Rental-Service-Traveller-26-Sitting.jpg"
              alt="Traveller (26 Seater)-Rental-Service"
            />
            <div class="card-body">
              <h5 class="card-title">Traveller (26 Seater)</h5>
              <Link to="/rental-service">
                <button className="btn btn-dark btn-lg btn-block p-1 mx-auto">
                  Book Now
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Rentals;

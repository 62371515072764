import React, { useState } from 'react';
import videoLocations from './Fun.js';
import './main.css';
import Blog from '../blog/Blog.js';

const Main = () => {
  const [location, setLocation] = useState('location1');

  const handleLocationChange = (event) => {
    const newLocation = event.target.value;
    setLocation(newLocation);
  };

  return (
    <>
      <div className="main-container">
        <video className="background-video" autoPlay loop muted key={location}>
          <source src={videoLocations[location].video} type="video/mp4" />
          Your browser does not support the video.
        </video>
        <select className="locations-options" value={location} onChange={handleLocationChange}>
          {Object.keys(videoLocations).map((key) => (
            <option key={key} value={key}>
              {videoLocations[key].heading}
            </option>
          ))}
        </select>
        <h4>{videoLocations[location].subheading}</h4>
      </div>
      {videoLocations[location].subpage}
      {videoLocations[location].subpage1}
      {videoLocations[location].subpage2}      
      {videoLocations[location].subpage3}
      {videoLocations[location].subpage4}
      <h5 className='latestblogs'>Latest Blogs</h5>
      <Blog/>
    </>
  );
};

export default Main;

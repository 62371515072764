const destinations = [
  {
    id: 1,
    name: "Bangkok & Pattaya",
    image: "https://i.ibb.co/5jpctrb/ASMS-Holidays-Bangkok-Pattaya-Image.webp",
    details: {
      title:
        "Discovering Treasures Beyond Boundaries: The Magic of Bangkok and Pattaya",
      intro:
        "In the heart of Thailand, a tale of two cities unfolds—one vibrant, bustling, and steeped in history; the other lively, coastal, and brimming with entertainment. Bangkok and Pattaya, each with its own unique charm, come together to create a symphony of emotions that resonates with every traveler's heart. Join us on a journey through these captivating destinations, where ancient traditions blend with modern marvels, and where every street corner holds a promise of adventure and wonder.",
      tabletitle: "Table of Contents",
      table: [
        "Introduction: The Twin Charms of Bangkok and Pattaya",
        "Grandeur and Grace: Bangkok's Magnificent Temples",
        "Floating Dreams: Exploring the Enchanting Floating Markets",
        "Chao Phraya River: Navigating Bangkok's Lifeline of Emotions",
        "Street Food Serenade: Savoring Bangkok's Culinary Melodies",
        "The Luminous Skyline: Bangkok's Nightscape of Dreams",
        "Pattaya's Beachfront Symphony: Sun, Sand, and Bliss",
        "Walking Street Chronicles: Pattaya's Nightlife Extravaganza",
        "Nong Nooch Tropical Garden: A Botanical Ballet of Beauty",
        "Coral Dreams: Snorkeling Adventures in Pattaya",
        "Sanctuaries of Serenity: Temples and Shrines of Pattaya",
        "Art in Paradise: Pattaya's 3D Illusion Museum",
        "Journey to the Hills: Khao Pattaya Viewpoint's Emotional Vista",
        "Shopping Havens: Retail Therapy in Bangkok and Pattaya",
        "Conclusion: Hearts Enriched, Memories Engraved",
      ],
      sections: [
        {
          title: "Introduction: The Twin Charms of Bangkok and Pattaya",
          content:
            "Bangkok and Pattaya, though distinct in character, share a bond that transcends their geographical proximity. Together, they offer a kaleidoscope of experiences, where ancient traditions and modern pursuits interweave to create an emotional tapestry.",
        },
        {
          title: "Grandeur and Grace: Bangkok's Magnificent Temples",
          content:
            "Step into a world of spiritual splendor as Bangkok unveils its majestic temples. From the intricate beauty of Wat Arun to the sacred tranquility of Wat Phra Kaew, each temple is a testament to human devotion and artistic brilliance.",
        },
        {
          title: "Floating Dreams: Exploring the Enchanting Floating Markets",
          content:
            "Navigate Bangkok's winding canals and embrace the vibrant charm of its floating markets. As you drift along, surrounded by a chorus of colors and aromas, you'll feel a connection to the rhythm of local life.",
        },
        {
          title: "Chao Phraya River: Navigating Bangkok's Lifeline of Emotions",
          content:
            "Embark on a river cruise along the Chao Phraya River, where every bend reveals a new facet of Bangkok's soul. As the city's heartbeat, the river carries whispers of history and dreams, inviting you to explore its secrets.",
        },
        {
          title: "Street Food Serenade: Savoring Bangkok's Culinary Melodies",
          content:
            "Let the streets of Bangkok serenade your taste buds with a symphony of flavors. From savory Pad Thai to aromatic bowls of Tom Yum Goong, each dish is a harmonious blend of ingredients that tell a tale of Thai culture.",
        },
        {
          title: "The Luminous Skyline: Bangkok's Nightscape of Dreams",
          content:
            "As the sun sets, Bangkok's skyline transforms into a canvas of lights that paints the night with a spectrum of emotions. Gaze upon the luminous cityscape from high above and let its beauty stir your heart.",
        },
        {
          title: "Pattaya's Beachfront Symphony: Sun, Sand, and Bliss",
          content:
            "Pattaya's beaches are an invitation to unwind and embrace the soothing melody of the sea. Let the gentle lapping of waves be your soundtrack as you bask in the sun's warm embrace.",
        },
        {
          title: "Walking Street Chronicles: Pattaya's Nightlife Extravaganza",
          content:
            "As night falls, Pattaya's Walking Street comes alive with a vibrant display of lights, music, and energy. Lose yourself in the rhythm of the night and let the city's nightlife take you on an emotional journey.",
        },
        {
          title: "Nong Nooch Tropical Garden: A Botanical Ballet of Beauty",
          content:
            "Step into a world of lush greenery and vibrant blooms at Nong Nooch Tropical Garden. This botanical paradise is a dance of colors and fragrances, a testament to nature's ability to evoke emotions.",
        },
        {
          title: "Coral Dreams: Snorkeling Adventures in Pattaya",
          content:
            "Dive into the crystal-clear waters of Pattaya and uncover a world of underwater wonders. Snorkeling amidst colorful coral reefs and playful marine life is a sensory journey that awakens your sense of wonder.",
        },
        {
          title: "Sanctuaries of Serenity: Temples and Shrines of Pattaya",
          content:
            "Pattaya's temples and shrines offer a haven of peace amidst the city's vibrancy. Explore the spiritual side of Pattaya as you discover sacred spaces that invite reflection and connection.",
        },
        {
          title: "Art in Paradise: Pattaya's 3D Illusion Museum",
          content:
            "Step into a realm where art transcends reality at Pattaya's Art in Paradise. Immerse yourself in optical illusions that challenge perception and ignite your imagination.",
        },
        {
          title:
            "Journey to the Hills: Khao Pattaya Viewpoint's Emotional Vista",
          content:
            "Climb to the Khao Pattaya Viewpoint and let your gaze sweep across a panoramic emotional vista. The breathtaking view of Pattaya's coastline and the boundless ocean is a reminder of the vastness of possibilities.",
        },
        {
          title: "Shopping Havens: Retail Therapy in Bangkok and Pattaya",
          content:
            "Indulge in retail therapy as you explore Bangkok's bustling markets and Pattaya's vibrant shopping districts. From traditional crafts to modern fashion, each purchase becomes a cherished memento.",
        },
        {
          title: "Conclusion: Hearts Enriched, Memories Engraved",
          content:
            "Bangkok and Pattaya are not just destinations; they are chapters in the book of your life's adventures. The memories you create, the emotions you experience, and the connections you forge will forever be etched in your heart.",
        },
      ],
      faqs: [
        {
          question:
            "Are there cultural etiquette guidelines for visiting temples in Bangkok and Pattaya?",
          answer:
            "Yes, when visiting temples, it's advisable to dress modestly and remove your shoes before entering. Respectful behavior is appreciated.",
        },
        {
          question: "What is Songkran and how is it celebrated in Thailand?",
          answer:
            "Songkran is the Thai New Year, celebrated with water fights and traditional rituals. It's a time of joy, renewal, and community.",
        },
        {
          question: "Can you explore Pattaya's islands from the mainland?",
          answer:
            "Yes, Pattaya offers island-hopping tours that allow you to explore nearby islands, each with its own unique charm and activities.",
        },
        {
          question:
            "What is the best way to travel between Bangkok and Pattaya?",
          answer:
            "You can travel between Bangkok and Pattaya by bus, train, or private transfer. Each option offers a unique perspective of the journey.",
        },
      ],
    },
  },
  {
    id: 2,
    name: "Vietnam",
    image: "https://i.ibb.co/WgKcrYd/ASMSHolidays-vietnam-image.webp",
    details: {
      title: "Exploring the Enchanting Beauty of Vietnam: A Traveler's Delight",
      intro:
        "Vietnam, a country steeped in rich history and natural beauty, has become a captivating destination for travelers seeking a unique and diverse experience. From bustling cities to serene landscapes, Vietnam offers a tapestry of culture, adventure, and relaxation. In this article, we will embark on a journey through Vietnam's hidden gems, iconic landmarks, delectable cuisine, and vibrant culture. Whether you're a history buff, an adventurous soul, or a food enthusiast, Vietnam has something for everyone.",
      tabletitle: "Table of Contents",
      table: [
        "Introduction: Unveiling the Charms of Vietnam",
        "Vietnam's Cultural Kaleidoscope: Exploring Hanoi",
        "Sailing Through Time: The Mekong Delta Adventure",
        "Halong Bay: Nature's Grandeur on Display",
        "Hoi An: Where Ancient and Modern Worlds Converge",
        "The Enigmatic Allure of Hue's Imperial City",
        "Thrills and Tranquility: Da Nang's Dual Personality",
        "Feasting on Flavor: Vietnamese Gastronomy",
        "Vietnam's Pristine Beaches and Islands",
        "Trekking the Terraced Landscapes of Sapa",
        "Ho Chi Minh City: A Blend of Past and Present",
        "Exploring the Caves of Phong Nha-Ke Bang National Park",
        "Unwinding in Dalat: The City of Eternal Spring",
        "Adventures in Nha Trang: Sun, Sand, and Sea",
        "Conclusion: A Tapestry of Experiences Awaits in Vietnam",
      ],
      sections: [
        {
          title: "Introduction: Unveiling the Charms of Vietnam",
          content:
            "Welcome to the enchanting land of Vietnam, where centuries-old traditions harmoniously coexist with modern dynamism. This Southeast Asian gem boasts a myriad of experiences, from bustling markets and ancient temples to breathtaking landscapes that will leave you awestruck. Join us as we traverse through the vibrant streets of Hanoi, cruise along the Mekong Delta, explore the stunning rock formations of Halong Bay, and delve into the cultural wonders of this captivating nation.",
        },
        {
          title: "Vietnam's Cultural Kaleidoscope: Exploring Hanoi",
          content:
            "Our journey begins in Hanoi, the heart and soul of Vietnam. The city pulsates with energy, offering a sensory overload of sights, sounds, and flavors. The Old Quarter, with its labyrinthine streets, is a treasure trove of history, where each corner tells a tale of the past. Visit the revered Temple of Literature, a testament to Vietnam's scholarly heritage, and marvel at the iconic Hoan Kiem Lake. Don't forget to savor a steaming bowl of pho, Vietnam's quintessential noodle soup.",
        },
        {
          title: "Sailing Through Time: The Mekong Delta Adventure",
          content:
            "Leaving the urban bustle behind, we set sail on the iconic Mekong Delta. This intricate network of waterways introduces us to the rural charm of Vietnam. Immerse yourself in the floating markets, where boats brimming with fresh produce create a kaleidoscope of colors. Witness the age-old tradition of mat weaving and indulge in tropical fruits as we meander through the delta's labyrinthine canals.",
        },
        {
          title: "Halong Bay: Nature's Grandeur on Display",
          content:
            "A UNESCO World Heritage Site, Halong Bay beckons with its ethereal beauty. Imagine cruising through emerald waters, surrounded by towering limestone karsts adorned with lush greenery. Explore hidden caves, kayak in tranquil lagoons, and watch the sunset paint the sky in hues of orange and pink. Halong Bay is a testament to the raw power of nature's artistry.",
        },
        {
          title: "Hoi An: Where Ancient and Modern Worlds Converge",
          content:
            "In the charming town of Hoi An, history comes alive amidst a fusion of cultures. Stroll through well-preserved ancient streets adorned with lanterns, and witness the vibrant hues of traditional silk lantern-making. The Japanese Covered Bridge and the Fujian Assembly Hall offer a glimpse into Hoi An's storied past. Indulge in tailor-made clothing and relish delectable street food in this UNESCO-listed gem.",
        },
        {
          title: "The Enigmatic Allure of Hue's Imperial City",
          content:
            "Journeying to Hue, we step into Vietnam's imperial history. The imposing Citadel houses a treasure trove of ancient architecture, including the Forbidden Purple City. Embrace the elegance of the past as you explore the ornate tombs of emperors set against a backdrop of picturesque landscapes.",
        },
        {
          title: "Thrills and Tranquility: Da Nang's Dual Personality",
          content:
            "Da Nang effortlessly combines the vibrancy of city life with the tranquility of its natural surroundings. Thrill-seekers will find their paradise on the Golden Bridge, held by giant stone hands amidst Ba Na Hills. For those seeking relaxation, My Khe Beach's golden sands and crystal-clear waters offer a tranquil escape.",
        },
        {
          title: "Feasting on Flavor: Vietnamese Gastronomy",
          content:
            "No journey through Vietnam is complete without savoring its delectable cuisine. From the tangy delight of goi cuon (spring rolls) to the savory depths of pho and the robust flavors of banh mi, Vietnamese food is a symphony of taste. Delve into local markets, join cooking classes, and embrace the culinary secrets of this gastronomic haven.",
        },
        {
          title: "Vietnam's Pristine Beaches and Islands",
          content:
            "Vietnam's coastline is dotted with pristine beaches and idyllic islands. Relax on the powdery shores of Phu Quoc, snorkel in the azure waters of Con Dao, and explore the breathtaking beauty of Ninh Van Bay. Whether you seek solitude or adventure, Vietnam's coastal wonders offer an escape like no other.",
        },
        {
          title: "Trekking the Terraced Landscapes of Sapa",
          content:
            "For nature enthusiasts and avid trekkers, Sapa is a dream come true. The terraced rice fields etched into the mountains create a mesmerizing tapestry of green and gold. Embark on treks through ethnic minority villages, where warm smiles and traditional customs welcome you into their world.",
        },
        {
          title: "Ho Chi Minh City: A Blend of Past and Present",
          content:
            "Formerly known as Saigon, Ho Chi Minh City is a bustling metropolis that encapsulates Vietnam's progress. Skyscrapers stand alongside colonial-era architecture, and the War Remnants Museum offers a somber glimpse into the nation's history. Dive into the vibrant street food scene and explore the energetic nightlife that defines this city.",
        },
        {
          title: "Exploring the Caves of Phong Nha-Ke Bang National Park",
          content:
            "Venture into the depths of Phong Nha-Ke Bang National Park, a UNESCO-listed site famed for its cave systems. Discover the awe-inspiring Son Doong Cave, the world's largest cave, and marvel at the otherworldly formations within Paradise Cave. This subterranean journey unveils a surreal realm hidden beneath Vietnam's surface.",
        },
        {
          title: "Unwinding in Dalat: The City of Eternal Spring",
          content:
            "Escape the tropical heat in the charming highlands of Dalat. Known as the City of Eternal Spring, Dalat offers temperate weather, stunning gardens, and a romantic ambiance. Take a leisurely stroll around Xuan Huong Lake, visit the iconic Crazy House, and relish the tranquility that permeates this hillside retreat.",
        },
        {
          title: "Adventures in Nha Trang: Sun, Sand, and Sea",
          content:
            "Our journey concludes in Nha Trang, a coastal paradise that combines relaxation and adventure. Sink your toes into the soft sands of Nha Trang Beach, indulge in water sports and island-hopping, and rejuvenate with natural mud baths. Nha Trang's harmonious blend of nature and leisure provides the perfect finale to our exploration of Vietnam.",
        },
        {
          title: "Conclusion: A Tapestry of Experiences Awaits in Vietnam",
          content:
            "As our voyage through Vietnam comes to an end, one thing becomes abundantly clear – Vietnam is a land of boundless wonders. From its bustling cities to its tranquil landscapes, from its flavorful cuisine to its rich history, Vietnam offers a diverse tapestry of experiences that will forever imprint upon your soul. So, pack your bags, embark on this adventure, and let Vietnam's enchantment captivate you.",
        },
      ],
      faqs: [
        {
          question: "Is Vietnam a safe travel destination?",
          answer:
            "Indeed, Vietnam is commonly regarded as a secure haven for travelers. Nevertheless, it is advisable to exercise prudence and adhere to local regulations.",
        },
        {
          question: "What is the best time to visit Vietnam?",
          answer:
            "Optimal timing for a visit to Vietnam falls within its dry season, spanning from November through April. The weather is pleasant, and many regions are at their most beautiful.",
        },
        {
          question: "Is a visa required for traveling to Vietnam?",
          answer:
            "Most travelers to Vietnam require a visa. It's advisable to check the specific visa requirements based on your nationality before traveling.",
        },
        {
          question: "What are some must-try Vietnamese dishes?",
          answer:
            "Don't miss out on trying pho (noodle soup), banh mi (sandwich), goi cuon (spring rolls), and cha gio (spring rolls). Embarking on a culinary adventure through Vietnamese cuisine is a truly delightful experience.",
        },
        {
          question: "Are there any customs or etiquette I should be aware of?",
          answer:
            "Yes, respecting local customs is important. For example, it's polite to remove your shoes before entering someone's home and to dress modestly when visiting temples.",
        },
      ],
    },
  },
  {
    id: 3,
    name: "Bali",
    image: "https://i.ibb.co/RvLB7PT/ASMS-Holidays-Bali-Image.webp",
    details: {
      title: "Bali Travel Blog: A Journey of Serenity and Adventure",
      intro:
        "Bali, the jewel of Indonesia, is a paradise that beckons travelers with its enchanting landscapes, vibrant culture, and heartwarming experiences...",
      tabletitle: "Table of Contents",
      table: [
        "Introduction: Bali's Allure and Enchantment",
        "Discovering the Cultural Tapestry of Ubud",
        "Sun-Kissed Tranquility at Nusa Dua Beaches",
        "Embracing Spiritual Awakening at Tanah Lot Temple",
        "Surfing Adventures in Uluwatu",
        "A Glimpse of Underwater Paradise: Snorkeling in Amed",
        "Ubud's Rice Terraces: Nature's Staircase to Beauty",
        "Savoring Culinary Delights: A Journey Through Balinese Cuisine",
        "Sacred Monkey Forest Sanctuary: Where Humans and Nature Converge",
        "Exploring Bali's Craftsmanship: The Artisan Villages",
        "Thrills and Chills: White Water Rafting in Telaga Waja",
        "Mesmerizing Sunset at Kuta Beach",
        "Rejuvenation and Relaxation: Bali's Luxury Spas",
        "The Mystique of Mount Batur: Trekking to the Sunrise",
        "Conclusion: Capturing Bali's Soul in Heartfelt Memories",
      ],
      sections: [
        {
          title: "Introduction: Bali's Allure and Enchantment",
          content:
            "Bali, often referred to as the 'Island of the Gods,' casts a spell on visitors from the moment they set foot on its shores. The gentle rustle of palm trees, the scent of incense wafting through the air, and the warm smiles of the locals create an atmosphere of unparalleled serenity.",
        },
        {
          title: "Discovering the Cultural Tapestry of Ubud",
          content:
            "Nestled amidst lush rainforests and terraced rice paddies, Ubud is a haven for art and culture. The Ubud Royal Palace and the nearby Ubud Market showcase intricate Balinese craftsmanship, while the annual Ubud Writers and Readers Festival celebrate the written word.",
        },
        {
          title: "Sun-Kissed Tranquility at Nusa Dua Beaches",
          content:
            "The golden sands and turquoise waters of Nusa Dua beckon sunseekers and water enthusiasts alike. Whether you're lounging under a parasol or snorkeling amidst colorful marine life, Nusa Dua's beaches offer a slice of paradise.",
        },
        {
          title: "Embracing Spiritual Awakening at Tanah Lot Temple",
          content:
            "Perched on a rocky outcrop overlooking the Indian Ocean, Tanah Lot Temple is a sacred site that offers a profound spiritual experience, especially during mesmerizing sunsets when the temple is silhouetted against the fiery sky.",
        },
        {
          title: "Surfing Adventures in Uluwatu",
          content:
            "Thrill-seekers and surf enthusiasts find their paradise in Uluwatu, where towering waves create the perfect canvas for riding the ocean's energy. The Uluwatu Temple adds a touch of mystique to this vibrant surfing haven.",
        },
        {
          title: "A Glimpse of Underwater Paradise: Snorkeling in Amed",
          content:
            "Dive into the crystal-clear waters of Amed to discover a world beneath the surface teeming with vibrant coral reefs and marine life. Snorkeling in Amed is a symphony of colors and an exploration of underwater wonders.",
        },
        {
          title: "Ubud's Rice Terraces: Nature's Staircase to Beauty",
          content:
            "The emerald-green rice terraces of Ubud paint a picturesque landscape that showcases Bali's agrarian heritage. Walking through these terraces is like ascending nature's staircase to beauty and tranquility.",
        },
        {
          title:
            "Savoring Culinary Delights: A Journey Through Balinese Cuisine",
          content:
            "Bali's cuisine is a reflection of its diverse culture and fertile land. From succulent satays to aromatic rijsttafel feasts, indulging in Balinese delicacies is a sensory journey that delights the palate and soul.",
        },
        {
          title:
            "Sacred Monkey Forest Sanctuary: Where Humans and Nature Converge",
          content:
            "The Sacred Monkey Forest Sanctuary is a sanctuary for both macaques and humans. Amidst ancient temples and towering trees, visitors can witness the harmonious coexistence of nature and spirituality.",
        },
        {
          title: "Exploring Bali's Craftsmanship: The Artisan Villages",
          content:
            "Bali's artisan villages are a testament to the island's creative spirit. Celuk, known for its silverwork, and Batubulan, famous for stone carvings, offer a glimpse into the intricate craftsmanship that defines Bali.",
        },
        {
          title: "Thrills and Chills: White Water Rafting in Telaga Waja",
          content:
            "Navigate the exhilarating rapids of the Telaga Waja River for an adrenaline-pumping adventure. White water rafting not only offers a thrilling experience but also exposes you to Bali's breathtaking hinterlands.",
        },
        {
          title: "Mesmerizing Sunset at Kuta Beach",
          content:
            "Kuta Beach's vibrant energy and captivating sunsets make it a popular destination for surfers and sunset enthusiasts alike. As the sun dips below the horizon, it paints the sky in hues of orange and pink, creating a mesmerizing spectacle.",
        },
        {
          title: "Rejuvenation and Relaxation: Bali's Luxury Spas",
          content:
            "Bali's luxury spas offer a retreat for the weary soul. Indulge in traditional massages, healing therapies, and holistic treatments that rejuvenate your body, mind, and spirit amidst lush surroundings.",
        },
        {
          title: "The Mystique of Mount Batur: Trekking to the Sunrise",
          content:
            "Embark on a pre-dawn trek up Mount Batur to witness the breathtaking sunrise from its summit. The rugged beauty of the volcanic landscape combined with the dawn's first light creates an awe-inspiring experience.",
        },
        {
          title: "Conclusion: Capturing Bali's Soul in Heartfelt Memories",
          content:
            "Bali is more than just an island; it's a tapestry of emotions woven with threads of serenity, adventure, and cultural richness. From tranquil beaches to daring escapades, Bali leaves an imprint on your heart, ensuring that each memory etched during your journey remains a treasure forever.",
        },
      ],
      faqs: [
        {
          question: "Is Bali suitable for solo travelers?",
          answer:
            "Absolutely! Bali is a welcoming destination for solo travelers, offering a mix of social interactions and serene solitude.",
        },
        {
          question: "What is the best time to visit Bali?",
          answer:
            "The best time to visit Bali is during the dry season, from April to September, when the weather is sunny and ideal for outdoor activities.",
        },
        {
          question:
            "Are there vegetarian options available in Balinese cuisine?",
          answer:
            "Yes, Balinese cuisine offers a variety of delicious vegetarian dishes that cater to different tastes and preferences.",
        },
        {
          question: "Can I participate in temple ceremonies as a tourist?",
          answer:
            "While some temples welcome tourists, it's important to respect the customs and traditions. Proper attire and respectful behavior are essential.",
        },
        {
          question: "Are there any off-the-beaten-path destinations in Bali?",
          answer:
            "Yes, Bali has numerous hidden gems like Munduk, Sidemen, and Menjangan Island, which offer a more tranquil and authentic experience.",
        },
      ],
    },
  },
  {
    id: 4,
    name: "Dubai",
    image: "https://i.ibb.co/rbmn65r/ASMS-Holidays-Dubai-Image.jpg",
    details: {
      title: "Embarking on a Journey of Wonders: Dubai Unveiled",
      intro:
        "In the heart of the desert, a city of dreams awaits. Dubai, a dazzling jewel in the United Arab Emirates, beckons travelers with promises of opulence, innovation, and boundless experiences...",
      tabletitle: "Table of Contents",
      table: [
        "Introduction: Dubai's Allure and Splendor",
        "The Majestic Burj Khalifa: Reaching for the Stars",
        "A Desert Dream: Exploring the Dubai Desert Conservation Reserve",
        "Old Dubai's Charms: A Stroll Through Al Fahidi Historic District",
        "The Enigmatic Dubai Fountain: Dance of Water and Light",
        "Sailing into Sunset: Dhow Cruise Along Dubai Creek",
        "The Dubai Mall: Where Shopping Becomes an Adventure",
        "Culinary Odyssey: Indulging in Dubai's Diverse Flavors",
        "Awe-Inspiring Miracle Garden: Nature's Artistry in the Desert",
        "Thrills of the Arabian Nights: Adventures in Dubai's Sand Dunes",
        "Immersive Entertainment: Dubai Opera's Cultural Symphony",
        "Palm Jumeirah: A Luxurious Oasis in the Arabian Gulf",
        "Heritage and Luxury Intertwined: The Ritz-Carlton, Dubai",
        "Aquaventure Waterpark: A Dive into Excitement and Playfulness",
        "Conclusion: Captivated Hearts, Endless Memories",
      ],
      sections: [
        {
          title: "Introduction: Dubai's Allure and Splendor",
          content:
            "Dubai is a symphony of modern marvels and timeless traditions. Its skyline, adorned with architectural wonders, is a testament to human ingenuity and ambition. Yet, beyond the steel and glass lies an enchanting city that cherishes its cultural roots and embraces the future with open arms.",
        },
        {
          title: "The Majestic Burj Khalifa: Reaching for the Stars",
          content:
            "Standing tall and proud, the Burj Khalifa is more than just the world's tallest building; it's a testament to Dubai's determination to touch the sky. Ascend to its heights and be swept away by panoramic views that stretch beyond the horizon.",
        },
        {
          title:
            "A Desert Dream: Exploring the Dubai Desert Conservation Reserve",
          content:
            "Venture into the heart of the desert and discover the Dubai Desert Conservation Reserve—a sanctuary where time seems to stand still. Immerse yourself in the tranquility of golden sands and let the desert whisper its ancient tales.",
        },
        {
          title:
            "Old Dubai's Charms: A Stroll Through Al Fahidi Historic District",
          content:
            "Amidst the modernity, Al Fahidi Historic District stands as a living testament to Dubai's past. Lose yourself in narrow alleys, wind towers, and bustling souks, where the echoes of history beckon with every step.",
        },
        {
          title: "The Enigmatic Dubai Fountain: Dance of Water and Light",
          content:
            "As the sun sets, the Dubai Fountain comes to life, a mesmerizing dance of water and light set against the backdrop of the Burj Khalifa. Let the music and spectacle ignite your senses and stir your emotions.",
        },
        {
          title: "Sailing into Sunset: Dhow Cruise Along Dubai Creek",
          content:
            "Step aboard a traditional dhow and set sail along the Dubai Creek. Watch the sun dip below the horizon as you glide past iconic landmarks, and feel the gentle embrace of the wind as it weaves tales of old and new.",
        },
        {
          title: "The Dubai Mall: Where Shopping Becomes an Adventure",
          content:
            "The Dubai Mall is not just a shopping destination; it's a realm of wonder and excitement. From high-end boutiques to indoor ice rinks and underwater zoos, every corner of this colossal mall is an adventure waiting to unfold.",
        },
        {
          title: "Culinary Odyssey: Indulging in Dubai's Diverse Flavors",
          content:
            "Dubai's culinary scene is a celebration of global influences and local flavors. From aromatic spices of Emirati cuisine to Michelin-starred delights, every bite is a journey that transcends borders.",
        },
        {
          title:
            "Awe-Inspiring Miracle Garden: Nature's Artistry in the Desert",
          content:
            "In the heart of the desert blooms a marvel that defies the odds—the Dubai Miracle Garden. Witness a kaleidoscope of colors as millions of flowers come together to create an enchanting oasis.",
        },
        {
          title:
            "Thrills of the Arabian Nights: Adventures in Dubai's Sand Dunes",
          content:
            "Unleash your inner adventurer and embark on a thrilling desert safari. Feel the rush as you navigate the undulating sand dunes, and experience the magic of the desert as the stars illuminate the night sky.",
        },
        {
          title: "Immersive Entertainment: Dubai Opera's Cultural Symphony",
          content:
            "Indulge in cultural performances and artistic masterpieces at the Dubai Opera. Whether it's a captivating ballet or a soul-stirring opera, each performance is a journey into the heart of human expression.",
        },
        {
          title: "Palm Jumeirah: A Luxurious Oasis in the Arabian Gulf",
          content:
            "Palm Jumeirah is a testament to Dubai's audacious vision. A man-made archipelago shaped like a palm tree, it offers a luxurious haven where sun, sea, and sophistication intertwine.",
        },
        {
          title: "Heritage and Luxury Intertwined: The Ritz-Carlton, Dubai",
          content:
            "Experience the epitome of luxury at The Ritz-Carlton, Dubai. Immerse yourself in opulence while embracing the rich cultural heritage that defines this lavish retreat.",
        },
        {
          title:
            "Aquaventure Waterpark: A Dive into Excitement and Playfulness",
          content:
            "Quench your thirst for adventure at Aquaventure Waterpark. Zoom down water slides, float along lazy rivers, and get up close with marine life—all in a world where excitement knows no bounds.",
        },
        {
          title: "Conclusion: Captivated Hearts, Endless Memories",
          content:
            "Dubai's allure lies not only in its grandeur but in its ability to touch the soul. It's a city where emotions are woven into every experience, leaving a trail of captivating memories that linger long after you've bid farewell.",
        },
      ],
      faqs: [
        {
          question: "Is Dubai suitable for family vacations?",
          answer:
            "Absolutely! Dubai offers a plethora of family-friendly attractions, from theme parks to beaches and cultural experiences.",
        },
        {
          question: "What is the local currency in Dubai?",
          answer:
            "Embracing the Pulse of Dubai, every transaction dances to the rhythm of the United Arab Emirates Dirham (AED), the city's heartbeat and the key that unlocks its treasures.",
        },
        {
          question:
            "Are there opportunities to experience local culture in Dubai?",
          answer:
            "Yes, Dubai embraces its heritage through various cultural attractions, museums, and festivals.",
        },
        {
          question: "Is it advisable to visit Dubai during Ramadan?",
          answer:
            "Visiting Dubai during Ramadan offers a unique cultural experience, but be mindful of the local customs and traditions.",
        },
        {
          question: "Can tourists visit the Burj Khalifa?",
          answer:
            "Yes, tourists can visit the Burj Khalifa and experience its breathtaking observation decks.",
        },
      ],
    },
  },
  {
    id: 5,
    name: "Maldives",
    image: "https://i.ibb.co/7nfwrPn/ASMS-Holidays-Maldives-Image.jpg",
    details: {
      title: "A Serenade of Turquoise Dreams: Unveiling the Maldives",
      intro:
        "In the heart of the Indian Ocean lies a paradise that awakens the senses and soothes the soul—the Maldives...",
      tabletitle: "Table of Contents",
      table: [
        "Introduction: The Maldives' Ethereal Charms",
        "Breathtaking Overwater Bungalows: Living on Azure Waters",
        "Snorkeling with Splendor: Exploring the Coral Reefs",
        "The Radiant Glow of Bioluminescent Beaches",
        "Cultural Treasures: Discovering Maldives' Rich Heritage",
        "Dolphin Serenades: Dancing with the Ocean's Playful Spirits",
        "Cuisine from the Heart: Savoring Maldivian Delights",
        "Secluded Escapes: Unveiling Hidden Island Retreats",
        "Adventures in the Blue: Diving into Oceanic Wonders",
        "Sunsets and Stargazing: Nature's Evening Extravaganza",
        "Preserving Paradise: Maldives' Commitment to Conservation",
        "Romantic Havens: Crafting Love Stories in the Maldives",
        "Luxury Unparalleled: Maldives' Opulent Resorts",
        "Island Hopping: A Journey through Diversity",
        "Conclusion: The Maldives' Embrace, Forever in Your Heart",
      ],
      sections: [
        {
          title: "Introduction: The Maldives' Ethereal Charms",
          content:
            "The Maldives is a whisper of paradise that floats upon the canvas of the Indian Ocean. Its allure lies not only in its pristine beaches and crystalline waters but in the sense of serenity that envelops you from the moment you arrive.",
        },
        {
          title: "Breathtaking Overwater Bungalows: Living on Azure Waters",
          content:
            "Step into a world of luxury and romance as you settle into an overwater bungalow, where your every waking moment is a reflection of the mesmerizing expanse of turquoise that surrounds you.",
        },
        {
          title: "Snorkeling with Splendor: Exploring the Coral Reefs",
          content:
            "Dip beneath the surface of the ocean and enter a realm of vibrant colors and marine wonders. Snorkeling in the Maldives is a ballet of grace and awe, as you glide alongside playful fish and delicate coral gardens.",
        },
        {
          title: "The Radiant Glow of Bioluminescent Beaches",
          content:
            "As night falls, witness a spectacle that defies imagination—the bioluminescent beaches of the Maldives. Each step you take is illuminated by a trail of shimmering stardust, a reminder of the magic that nature weaves.",
        },
        {
          title: "Cultural Treasures: Discovering Maldives' Rich Heritage",
          content:
            "Beyond its natural beauty, the Maldives holds a tapestry of culture and tradition. Delve into the stories of its people, from ancient legends to intricate craftsmanship, and embrace the warmth of Maldivian hospitality.",
        },
        {
          title: "Dolphin Serenades: Dancing with the Ocean's Playful Spirits",
          content:
            "Embark on a voyage of wonder as you set sail in search of dolphins. Watch as these joyful creatures surf the waves alongside your vessel, their laughter echoing in the salty breeze.",
        },
        {
          title: "Cuisine from the Heart: Savoring Maldivian Delights",
          content:
            "Indulge in a culinary journey that tantalizes your taste buds with flavors as vibrant as the ocean hues. Maldivian cuisine is a fusion of spices, coconut, and fresh seafood, served with a side of ocean views.",
        },
        {
          title: "Secluded Escapes: Unveiling Hidden Island Retreats",
          content:
            "Discover your own private haven on a secluded Maldivian island. With powdery sands and swaying palms as your companions, let the world slip away as you embrace the tranquility of solitude.",
        },
        {
          title: "Adventures in the Blue: Diving into Oceanic Wonders",
          content:
            "Plunge into the depths of the ocean and uncover a universe teeming with life. The Maldives' underwater world is a living masterpiece, where manta rays glide and sharks patrol, offering a thrilling dance of nature.",
        },
        {
          title: "Sunsets and Stargazing: Nature's Evening Extravaganza",
          content:
            "As the sun dips below the horizon, the sky transforms into a canvas of fiery hues, painting a breathtaking backdrop for your evening. And when darkness falls, witness the stars twinkling like diamonds, revealing the universe's secrets.",
        },
        {
          title: "Preserving Paradise: Maldives' Commitment to Conservation",
          content:
            "The Maldives is not only a destination to be cherished; it's a sanctuary to be protected. Discover the initiatives dedicated to preserving its natural wonders and the role you can play in this noble endeavor.",
        },
        {
          title: "Romantic Havens: Crafting Love Stories in the Maldives",
          content:
            "Unveil the chapters of your love story in the Maldives, where romance is woven into every moment. Walk hand in hand along moonlit beaches, share intimate dinners by the ocean, and create memories that transcend time.",
        },
        {
          title: "Luxury Unparalleled: Maldives' Opulent Resorts",
          content:
            "Indulge in the lap of luxury at the Maldives' world-renowned resorts. From private pools to intuitive service, each moment is designed to elevate your experience to new heights of opulence.",
        },
        {
          title: "Island Hopping: A Journey through Diversity",
          content:
            "Embark on a voyage of discovery as you hop from one captivating island to another. Each stop offers a unique slice of Maldivian life, from bustling markets to serene shores.",
        },
        {
          title: "Conclusion: The Maldives' Embrace, Forever in Your Heart",
          content:
            "The gentle embrace of the Maldivian breeze whispers secrets to your soul, weaving an emotional tapestry of serenity and wonder that becomes a part of who you are. It's a symphony of sensations that lingers in your heart long after you've bid adieu. Let the memory of its turquoise waters and golden sunsets be a source of comfort and inspiration, a reminder that paradise is never far away.",
        },
      ],
      faqs: [
        {
          question: "Are the Maldives only for honeymooners?",
          answer:
            "While the Maldives is a popular honeymoon destination, it offers a variety of experiences for all types of travelers, from families to solo adventurers.",
        },
        {
          question: "What is the best time to visit the Maldives?",
          answer:
            "The Maldives' dry season, from November to April, is ideal for sunny days and clear waters, perfect for outdoor activities.",
        },
        {
          question: "Can I explore local islands in the Maldives?",
          answer:
            "Yes, you can experience the local culture by visiting inhabited islands, interacting with locals, and participating in cultural activities.",
        },
        {
          question: "Is scuba diving suitable for beginners in the Maldives?",
          answer:
            "Absolutely! The Maldives offers a range of diving experiences suitable for beginners to experienced divers, with its calm lagoons and diverse marine life.",
        },
        {
          question:
            "What tender notes dance through the heart of the Maldives?",
          answer:
            "The Maldivian Rufiyaa (MVR) is the official currency, but US dollars are widely accepted in resorts and tourist areas.",
        },
      ],
    },
  },

  // ... add more destinations as needed
];

export default destinations;

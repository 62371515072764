import { Link } from "react-router-dom";
function Kerala() {
  const images = [
    'https://i.ibb.co/yqyrNXD/ASMSHolidays-honeymoon-Kerala1.jpg',
    'https://i.ibb.co/jkgn5sZ/ASMSHolidays-honeymoon-Kerala2.jpg',
    'https://i.ibb.co/sRcvvWM/ASMSHolidays-honeymoon-Kerala3.webp',
    'https://i.ibb.co/h8Y32q7/ASMSHolidays-honeymoon-Kerala4.jpg',
  ];

  return (
    <>
      <div className="honeymoon-title-container">
        <h3 className="andaman-honeymoon-heading">Majestic Kerala Honeymoon: Embarking on Nature's Romantic Odyssey</h3>
      </div>
      <div className="honeymoon-box">
        <div className="photo-collage">
          <div class="row">
            <div class="col-md-12">
              <div className="left-column card-body">
              <h5 className="honeymoon-subheading">
              Kerala: An Exquisite Honeymoon Amidst Nature's Verdant Paradise
            </h5>
            <p className="honeymoon-subpara">
              Experience the charm and serenity of Kerala on your romantic honeymoon adventure.
              Discover the lush green landscapes, tranquil backwaters, and pristine beaches that
              make Kerala a haven for couples. Float along the tranquil backwaters on a traditional
              houseboat, immerse in the healing power of Ayurvedic spa treatments, and witness
              breathtaking sunsets over the Arabian Sea. Indulge in the flavors of authentic Kerala
              cuisine, explore the rich cultural heritage, and embark on wildlife safaris in the
              picturesque national parks. Let Kerala's natural beauty and warm hospitality create
              unforgettable moments of love and togetherness. Embark on a romantic odyssey in God's
              Own Country and cherish the memories for a lifetime.
            </p>
            <Link to="/honeymoon-form">
           <button className="HoneymoonLocationbtn">Contact Us Now</button>
          </Link>
          </div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div className="right-column">
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                className="img-fluid"
                alt={`Image ${index + 1}`}
              />
            ))}
          </div>
        </div>
      </div>
      <hr />
    </>
  );
}

export default Kerala;

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'swiper/css'; // Import Swiper CSS globally


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
import './hotels.css';
import { useForm, ValidationError } from '@formspree/react';

function Hotels() {
  const [state, handleSubmit] = useForm("mdorkpvv");

  if (state.succeeded) {
    return <p className="success-message">Thanks for submitting the form our team will connect you Within 2 Working Hours</p>;
  }

  return (
    <div className="hotels-container">
      <div className="hotel-banner">
        <img
          src="https://i.ibb.co/ZJycx4N/Green-Modern-Hotel-Advertisement-Instagram-Post-3.png"
          className="hotel-banner-img"
          alt="Hotel Banner"
        />
        <div className="hotel-form">
          <form onSubmit={handleSubmit}>
            <h3 className="form-heading">
              Submit the Form and Expect a Response Within 2 Working Hours
            </h3>
            <div>
              <label htmlFor="name" className='hotels-label'>
                Name<span className="required-marker">*</span>:
              </label>
              <input
                id="name"
                type="text"
                name="name"
                required
              />
              <ValidationError
                prefix="name"
                field="name"
                errors={state.errors}
              />
            </div>
            <div>
              <label htmlFor="email" className='hotels-label'>
                Email<span className="required-marker">*</span>:
              </label>
              <input
                id="email"
                type="email"
                name="email"
                required
              />
              <ValidationError
                prefix="email"
                field="email"
                errors={state.errors}
              />
            </div>
            <div>
              <label htmlFor="phone" className='hotels-label'>
                Phone<span className="required-marker">*</span>:
              </label>
              <input
                id="phoneNum"
                type="tel"
                name="phoneNumber"
                required
              />
              <ValidationError
                prefix="phoneNum"
                field="phoneNum"
                errors={state.errors}
              />
            </div>
            <div>
              <label htmlFor="checkInDate" className='hotels-label'>
                Check-in Date<span className="required-marker">*</span>:
              </label>
              <input
                type="date"
                id="checkInDate"
                name="checkInDate"
                required
              />
              <ValidationError
                prefix="checkInDate"
                field="checkInDate"
                errors={state.errors}
              />
            </div>
            <div>
              <label htmlFor="checkOutDate" className='hotels-label'>
                Check-out Date<span className="required-marker">*</span>:
              </label>
              <input
                type="date"
                id="checkOutDate"
                name="checkOutDate"
                required
              />
              <ValidationError
                prefix="checkOutDate"
                field="checkOutDate"
                errors={state.errors}
              />
            </div>
            <div>
              <label htmlFor="location" className='hotels-label'>
                Location<span className="required-marker">*</span>:
              </label>
              <select
                id="location"
                name='location'
                required
              >
                <option value="">Select Location</option>
                <option value="Andaman">Andaman</option>
                <option value="Shimla">Shimla & Manali</option>
                <option value="Massrooi">Kashmir</option>
                <option value="Lakshadeep">Lakshadeep</option>
                <option value="Lakshadeep">Mumbai</option>
                <option value="Lakshadeep">Goa</option>
                <option value="Lakshadeep">Rajasthan</option>
                <option value="Lakshadeep">Kerala</option>
              </select>
              <ValidationError
                prefix="location"
                field="location"
                errors={state.errors}
              />
            </div>
            <div>
              <label htmlFor="numRooms" className='hotels-label'>
                Number of Rooms<span className="required-marker">*</span>:
              </label>
              <input
                type="number"
                id="numRooms"
                name="numRooms"
                required
              />
              <ValidationError
                prefix="numRooms"
                field="numRooms"
                errors={state.errors}
              />
            </div>
            <div>
              <label htmlFor="numAdults" className='hotels-label'>
                Number of Persons (Adult)<span className="required-marker">*</span>:
              </label>
              <input
                type="number"
                id="numAdults"
                name="numAdults"
                required
              />
              <ValidationError
                prefix="numAdults"
                field="numAdults"
                errors={state.errors}
              />
            </div>
            <div>
              <label htmlFor="numChildren" className='hotels-label'>
                Number of Persons (Child)<span className="required-marker">*</span>:
              </label>
              <input
                type="number"
                id="numChildren"
                name="numChildren"
                required
              />
              <ValidationError
                prefix="numChildren"
                field="numChildren"
                errors={state.errors}
              />
            </div>
            <div>
              <label htmlFor="hotelStarRating" className='hotels-label'>
                Hotel Star Rating:
              </label>
              <select
                id="hotelStarRating"
                name="hotelStarRating"
              >
                <option value="">Select Star Rating</option>
                <option value="1">1 Star</option>
                <option value="2">2 Stars</option>
                <option value="3">3 Stars</option>
                <option value="4">4 Stars</option>
                <option value="5">5 Stars</option>
              </select>
              <ValidationError
                prefix="hotelStarRating"
                field="hotelStarRating"
                errors={state.errors}
              />
            </div>
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Hotels;

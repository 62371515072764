import React from "react";
import { useParams } from "react-router-dom";
import "./InternationTrip.css";
import destinations from "./destinations"; // Import the detailed content

function InternationalTripDetails() {
  const { id } = useParams();
  const selectedDestination = destinations.find(
    (destination) => destination.id.toString() === id
  );

  if (!selectedDestination) {
    return <div>Destination not found</div>;
  }

  const { name, image, details } = selectedDestination;
  const { title, sections, faqs } = details;

  return (
    <div className="international-details-container">
      <h2>{title}</h2>
      <img src={image} alt={name} />
      {sections.map((section, index) => (
        <div key={index}>
          <h3>{section.title}</h3>
          <p>{section.content}</p>
        </div>
      ))}
      <h3>FAQs About {name} Travel</h3>
      <ul>
        {faqs.map((faq, index) => (
          <li key={index}>
            <strong>{faq.question}</strong> - {faq.answer}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default InternationalTripDetails;

import waveVideo from '../../Assest/videos/andaman.mp4';
import kashmirVideo from '../../Assest/videos/kashmir.mp4';
import shimlaVideo from '../../Assest/videos/shimla.mp4';
import mumbaiVideo from '../../Assest/videos/mumbai.mp4';
import islandVideo from '../../Assest/videos/island.mp4';
import goaVideo from '../../Assest/videos/goa.mp4';
import keralaVideo from '../../Assest/videos/kerala.mp4';
import rajasthanVideo from '../../Assest/videos/rajasthan.mp4';
import Andaman from './Andaman';
import ActivityCarousel from './ActivityCarousel';
import AndamanPrices from '../honeymoon/AndamanPrices';
import Cruise from './Cruise';
import Ferries from './Ferries';



const activitiesInAndaman= [
  {
      name: "Parasailing",
      description:
        "Soar Above the Crystal Clear Waters: Experience Thrilling Parasailing in Andaman. Glide through the sky, harnessed to a parachute, and marvel at the breathtaking views of pristine beaches and turquoise waters below. Whether you're a beginner or a thrill-seeker, parasailing in Andaman promises an unforgettable experience.",
      image: "https://i.ibb.co/WnG0DGY/andaman-parasailing.jpg",
    },
    {
      name: "Dolphin Ride",
      description:
        "Embark on an Exciting Dolphin Encounter: Enjoy a thrilling dolphin ride in Andaman and get up close with these intelligent and playful creatures. Cruise through the crystal clear waters of Andaman and witness the mesmerizing sight of dolphins leaping and frolicking in their natural habitat. This unforgettable experience will leave you with lasting memories of your Andaman adventure.",
      image:
        "https://i.ibb.co/S52y8nc/ASMSHolidays-Dolphin-Ride.jpg",
    },
    {
      name: "Sofa Ride",
      description:
        "Thrilling Water Adventure at North Bay Island: Get ready for an adrenaline-pumping experience with the exciting Sofa Ride at North Bay Island. This popular water sports destination is a paradise for adventure enthusiasts, offering a range of activities including snorkeling and scuba diving. Dive into the crystal-clear waters and explore the vibrant coral reefs teeming with diverse marine life. And when it comes to the Sofa Ride, hold on tight as you glide over the waves on an inflatable sofa, experiencing the thrill of speed and splashing water. Don't miss this exhilarating adventure during your visit to Andaman!",
      image:
        "https://i.ibb.co/TqqYT5k/ASMSHolidays-Sofa-Ride.jpg",
    },
    {
      name: "Black Eye Ride",
      description:
        "Thrilling Adventure amidst Natural Wonders: Embark on an unforgettable Black Eye Ride at Baratang Island, known for its extraordinary natural attractions. Brace yourself for a boat ride through dense mangroves, immersing yourself in the breathtaking beauty of the surroundings. Explore the mesmerizing limestone caves and witness the awe-inspiring mud volcanoes that make this island a true marvel. The Black Eye Ride offers a unique opportunity to witness these fascinating geological formations up close, creating memories that will last a lifetime. Don't miss out on this thrilling adventure during your visit to Andaman!",
      image:
        "https://i.ibb.co/JKmpZgy/ASMSHolidays-Black-Eye-ride.jpg",
    },
    {
      name: "Sea Walk",
      description:
        "Immerse Yourself in Underwater Bliss: Experience the mesmerizing Sea Walk at Neil Island, a tranquil paradise perfect for a peaceful getaway. Indulge in leisurely walks along pristine beaches, feeling the soft sand beneath your feet and enjoying the serene ambiance. Dive into the crystal-clear waters and embark on a fascinating journey of snorkeling, exploring the vibrant underwater world teeming with colorful marine life. The Sea Walk offers a unique opportunity to walk on the seabed, surrounded by stunning coral reefs and exotic aquatic creatures. Discover the beauty beneath the surface and create unforgettable memories during your visit to Andaman!",
      image:
        "https://i.ibb.co/JFkdZrS/ASMSHolidays-Sea-Walk.jpg",
    },
    {
      name: "Coral Safari",
      description:
        "Embark on an Unforgettable Coral Safari Adventure: Discover the marine wonders near Wandoor Beach, where a pristine national park awaits. Immerse yourself in a world of vibrant marine biodiversity as you embark on a captivating glass-bottom boat ride or thrilling snorkeling escapades. Marvel at the mesmerizing coral reefs and diverse marine species. Prepare to be amazed by the awe-inspiring sights and create lifelong memories during this exhilarating aquatic expedition!",
      image:
        "https://i.ibb.co/hXQSNgB/Asms-Holidays-Anadaman-Coral-Safari.jpg",
    },
    {
      name: "Snorkeling",
      description:
        "Dive into the Mystical Underwater Caves: Located in Diglipur, the limestone caves are a natural wonder with unique formations. Embark on an unforgettable snorkeling adventure and explore these magnificent caves. Marvel at the breathtaking stalactites and stalagmites that adorn the cave walls. Immerse yourself in the beauty of this mystical underwater world and create memories to last a lifetime.",
      image:
        "https://i.ibb.co/BBQQ9h5/ASMSHolidays-Andaman-snorkeling.jpg",
    },
    {
      name: "Disco Ride",
      description:
        "Dance on the Waves: Get ready for an exhilarating Disco Ride in the waters of Andaman. Situated on Mount Harriet, this national park provides a stunning backdrop as you embark on a thrilling water adventure. Hold on tight as you ride the waves on a high-speed watercraft, feeling the adrenaline rush through your veins. Experience the excitement, twists, and turns as you groove to the rhythm of the sea. It's a unique water sports experience that will leave you energized and craving for more.",
      image: "https://i.ibb.co/7n3D36G/ASMSHolidays-Anadaman-Disco-Ride.jpg",
    },
    {
      name: "Scuba Diving",
      description:
        "Dive into the Depths: Experience the mesmerizing world beneath the waves with Scuba Diving in Andaman. Situated on Mount Harriet, this national park serves as a gateway to an underwater paradise. Immerse yourself in the azure waters and explore the rich biodiversity teeming with vibrant coral reefs, colorful marine life, and fascinating underwater landscapes. With the guidance of certified instructors, dive to great depths and witness the wonders that lie beneath. It's an adventure that will leave you breathless and create memories to last a lifetime.",
      image:
        "https://i.ibb.co/d2QvPmv/asmsh-OLIDAYS-a-NDAMAN-Scuba-Diving02227.jpg",
    },
    {
      name: "Banana Ride",
      description:
        "Hold on Tight: Get ready for a thrilling adventure with the Banana Ride in Andaman. Experience the adrenaline rush as you hop on a banana-shaped inflatable boat and speed across the crystal-clear waters. Situated on Mount Harriet, this national park offers the perfect setting for this exciting water sport. Feel the wind in your hair and the splash of the waves as you hold on tight during this exhilarating ride. It's a fun-filled activity that guarantees laughter, excitement, and unforgettable moments with friends and family.",
      image:
        "https://i.ibb.co/2S9xy2V/ASMSHolidays-Andaman-banana-ride.webp",
    },
    {
      name: "Jet Ski",
      description:
        "Ride the Waves: Experience the thrill of Jet Skiing in the mesmerizing waters of Andaman. Situated on Mount Harriet, this national park provides the perfect backdrop for an exhilarating Jet Ski adventure. Hop on a high-speed watercraft and zoom across the azure waters, feeling the rush of adrenaline as you navigate through the waves. Enjoy the freedom and excitement of gliding over the water surface, surrounded by the stunning views of the Andaman archipelago. Whether you're a beginner or an experienced rider, Jet Skiing is a fantastic water sport that promises endless fun and excitement for all.",
      image:
        "https://i.ibb.co/5vCKJbK/ASMSHolidays-Andaman-Jet-Ski.jpg",
    },
     {
      name: "Sea Kart",
      description:
        "Embark on a thrilling Sea Kart adventure in the breathtaking Andaman archipelago. Explore the rich biodiversity and stunning views of the Andaman archipelago aboard a compact and maneuverable Sea Kart watercraft. Discover hidden gems, go hiking, and spot exotic flora and fauna on this unforgettable journey.",
      image:
        "https://i.ibb.co/SsFTKhF/ASMSHolidays-Andaman-Sea-kart.jpg",
    },
    {
      name: "Kayaking",
      description:
        "Embark on an adventurous kayaking expedition in the mesmerizing Andaman archipelago. Paddle through the azure waters and explore the rich biodiversity of the islands. Enjoy stunning views of the Andaman archipelago, go hiking, and have the opportunity to spot exotic flora and fauna along the way.",
      image:
        "https://i.ibb.co/z8JJ7J6/ASMSHolid-AYS-a-NDAMAN-Kayaking01.jpg",
    },
    {
      name: "Speed Boat Ride",
      description:
        "Experience the thrill of a speed boat ride in the captivating Andaman archipelago. Feel the wind in your hair as you zip across the crystal-clear waters, enjoying breathtaking views of the surrounding islands. Explore the rich biodiversity of the region, go hiking, and have the opportunity to spot exotic flora and fauna during your exhilarating adventure.",
      image:
        "https://i.ibb.co/8nw28Sj/ASMSHolidays-anadaman-Speed-boat.jpg",
    },
    {
      name: "Standing Ride",
      description:
        "Experience the ultimate thrill of a standing ride in the mesmerizing Andaman archipelago. Nestled within the enchanting Mount Harriet National Park, this exhilarating activity offers panoramic vistas of the pristine islands and showcases an extraordinary display of natural wonders. Immerse yourself in the untamed beauty of the park as you embark on an adrenaline-pumping adventure. Discover the diverse flora and fauna, traverse scenic trails, and create lifelong memories in this captivating paradise. The standing ride awaits, promising an exclusive and unforgettable experience like no other.",
      image:
        "https://i.ibb.co/h1Sk07B/ASMSHolidays-Anadaman-Standing-ride.webp",
    },
  ]

  const activitiesInShimlaManali = [
    {
      name: 'River Rafting',
      description: 'Experience the thrill of river rafting in the fast-flowing rivers of Shimla and Manali.',
      image: 'https://i.ibb.co/wwS9gHS/ASMSHolidays-River-Rafting-himachal-pradesh.webp',
    },
    {
      name: 'Paragliding',
      description: 'Fly like a bird and enjoy paragliding adventure in the scenic landscapes of Shimla and Manali.',
      image: 'https://i.ibb.co/x3JDsr2/ASMSHolidays-Manali-Shimala-paragliding.webp',
    },
    {
      name: 'Trekking',
      description: 'Explore the picturesque trails and stunning mountains of Shimla and Manali through trekking.',
      image: 'https://i.ibb.co/tm97wC5/ASMSHolidays-shimla-manali-trekking.jpg',
    },
    {
      name: 'Camping',
      description: 'Experience the beauty of nature by camping amidst the serene landscapes of Shimla and Manali.',
      image: 'https://i.ibb.co/vXMYqj5/ASMSHolidays-Shimal-Manali-campaing.webp',
    },
    {
      name: 'Mountain Biking',
      description: 'Go on an adventurous mountain biking journey and explore the rugged terrains of Shimla and Manali.',
      image: 'https://i.ibb.co/5WV99Dc/ASMSHolidays-Cycling-in-Shimla.webp',
    },
    {
      name: 'Hot Air Balloon Ride',
      description: 'Soar above the scenic landscapes of Shimla and Manali and enjoy a mesmerizing hot air balloon ride.',
      image: 'https://i.ibb.co/M591KKM/ASMSHolidays-Shimal-Manali-hot-air-balloon.jpg',
    },
    {
      name: 'Sightseeing',
      description: 'Visit the famous attractions and landmarks in Shimla and Manali for a memorable sightseeing experience.',
      image: 'https://i.ibb.co/BnHjkNM/ASMSHolidays-Shimal-Manali-Sight.jpg',
    },
    {
      name: 'Cable Car Ride',
      description: 'Take a cable car ride and enjoy panoramic views of the surrounding mountains and valleys in Shimla and Manali.',
      image: 'https://i.ibb.co/1LQwNtK/ASMSHolidays-Shimal-Manali-cable-car-Ride.png',
    },
    {
      name: 'Shopping',
      description: 'Indulge in some retail therapy and explore the vibrant markets of Shimla and Manali for souvenirs and local handicrafts.',
      image: 'https://i.ibb.co/QJJN99R/ASMSHolidays-Shimla-Manali-Shopping01.jpg',
    },
  ];

  const activitiesInKashmir = [
    {
      name: 'Shikara Ride in Dal Lake',
      description: 'Experience the tranquility of Dal Lake with a scenic shikara ride, surrounded by breathtaking views of the mountains and floating gardens.',
      image: 'https://i.ibb.co/L1xxyQk/ASMSHolidays-Kashmir-Boat-Ride.jpg',
    },
    {
      name: 'Trekking in the Himalayas',
      description: 'Embark on adventurous treks in the stunning Himalayan ranges of Kashmir, exploring picturesque trails and enjoying breathtaking views.',
      image: 'https://i.ibb.co/mRmKmRr/ASMSHolidays-Kashmir-trekking0.jpg',
    },
    {
      name: 'Skiing in Gulmarg',
      description: 'Enjoy thrilling skiing adventures in Gulmarg, known for its pristine snow-covered slopes and excellent skiing facilities.',
      image: 'https://i.ibb.co/n1BY3Mg/ASMSHolidays-Kashmir-Skiing0.jpg',
    },
    {
      name: 'Gondola Ride in Gulmarg',
      description: 'Take a ride on the Gulmarg Gondola, one of the highest cable cars in the world, offering panoramic views of the Himalayan peaks.',
      image: 'https://i.ibb.co/YNP0rk2/ASMSHolidays-Kashmir-Gondola-Ride01.webp',
    },
    {
      name: 'Visit to Mughal Gardens',
      description: 'Explore the beautiful Mughal Gardens in Srinagar, known for their stunning terraced lawns, vibrant flowerbeds, and historic architecture.',
      image: 'https://i.ibb.co/P1Rxbxp/ASMSHolidays-Kashmir-Mughal-Gardens0.webp',
    },
    {
      name: 'Water Rafting in Lidder River',
      description: 'Experience the thrill of white water rafting in the picturesque Lidder River, surrounded by breathtaking landscapes.',
      image: 'https://i.ibb.co/6gfWsYP/ASMSHolidays-river-rafting-kashmir2.jpg',
    },
    {
      name: 'Visit to Pahalgam',
      description: 'Discover the scenic town of Pahalgam, surrounded by lush meadows, pine forests, and stunning viewpoints, offering a perfect retreat for nature lovers.',
      image: 'https://i.ibb.co/hmzYK9b/ASMSHolidays-kashmir-Pahalgam02.jpg',
    },
    {
      name: 'Explore Sonamarg',
      description: 'Visit the enchanting town of Sonamarg, known as the "Meadow of Gold," and indulge in activities like horse riding and hiking amidst majestic glaciers and alpine lakes.',
      image: 'https://i.ibb.co/wdKFXrQ/ASMSholidays-Kashmir-sonmarg.jpg',
    },
    {
      name: 'Camping in the Kashmir Valley',
      description: 'Experience the serenity of the Kashmir Valley by camping amidst its breathtaking landscapes, enjoying starry nights and bonfires.',
      image: 'https://i.ibb.co/BBCdFq2/ASMSHolidays-Kashmir-Camping01.webp',
    },
    {
      name: 'Visit to Aru Valley',
      description: 'Explore the picturesque Aru Valley, known for its lush meadows, scenic beauty, and opportunities for adventure activities like hiking and horse riding.',
      image: 'https://i.ibb.co/pfnVkJQ/ASMSHolidays-Kashmir-aru-valley-pahalgam.jpg',
    },
  ];

  const activitiesInLakshadweep = [
    {
      name: 'Scuba Diving in Agatti Island',
      description: 'Explore the vibrant coral reefs and underwater marine life of Agatti Island through scuba diving, and witness the stunning beauty of the underwater world.',
      image: 'https://i.ibb.co/Wv6GGc5/ASMSHolidays-Lakshadep-scuba-diving.jpg',
    },
    {
      name: 'Island Hopping in Kadmat',
      description: 'Go on an island-hopping adventure in Kadmat, visiting pristine islands, enjoying beach activities, and experiencing the serene and untouched beauty of the archipelago.',
      image: 'https://i.ibb.co/x2z4yj6/ASMSHolidays-Lakshadeep-Kadmat-Island01.jpg',
    },
    {
      name: 'Snorkeling in Bangaram Island',
      description: 'Discover the colorful coral gardens and exotic marine life of Bangaram Island through snorkeling, immersing yourself in the crystal-clear waters of the lagoon.',
      image: 'https://i.ibb.co/ZN1WXkk/ASMSHolidays-snorkeling-in-lakshadweep.jpg',
    },
    {
      name: 'Beach Camping in Kavaratti',
      description: 'Experience the tranquility of Kavaratti by camping on its pristine beaches, enjoying the gentle ocean breeze, and stargazing under the clear night sky.',
      image: 'https://i.ibb.co/vLZKM3C/ASMSHolidays-Lakshadeep-Campaining.jpg',
    },
    {
      name: 'Fishing in Lakshadweep',
      description: 'Embark on a fishing excursion in the waters of Lakshadweep, trying your hand at traditional fishing methods and experiencing the thrill of catching your own seafood.',
      image: 'https://i.ibb.co/kQFn4g7/ASMSHolidays-Lakshadeep-Fishing01.jpg',
    },
    {
      name: 'Visit to Kalpeni Island',
      description: 'Explore the picturesque Kalpeni Island, known for its scenic beauty, white sandy beaches, and mesmerizing lagoons, offering a perfect getaway for nature lovers.',
      image: 'https://i.ibb.co/jyg9hzD/ASMSHolidays-lakshadeep-Kalpeni-Island-Sight-Seeing-Tour.jpg',
    },
    {
      name: 'Water Sports in Kavaratti',
      description: 'Indulge in thrilling water sports activities in Kavaratti, such as jet skiing, banana boat rides, and parasailing, and enjoy the adrenaline rush amidst the stunning island scenery.',
      image: 'https://i.ibb.co/m5HSqLb/Asms-Holidays-Lakshadeep-Water-Skiing.jpg',
    },
  ];

  const activitiesInMumbai = [
    {
      name: 'Gateway of India',
      description: 'Visit the iconic Gateway of India, a historical landmark and popular tourist attraction in Mumbai, known for its impressive architecture and panoramic views of the Arabian Sea.',
      image: 'https://i.ibb.co/4T8Sw8G/ASMSHolidays-Mumbai-Gateway-of-India-Mumbai.webp',
    },
    {
      name: 'Marine Drive',
      description: 'Take a leisurely stroll along Marine Drive, also known as the Queen\'s Necklace, and enjoy breathtaking views of the coastline, the sunset, and the bustling cityscape.',
      image: 'https://i.ibb.co/44q2LjZ/ASMSHolidays-Mumbai-Marine-Drive02.png',
    },
    {
      name: 'Elephanta Caves',
      description: 'Explore the ancient Elephanta Caves, a UNESCO World Heritage Site, located on Elephanta Island. Marvel at the intricate rock-cut sculptures and the rich history of the caves.',
      image: 'https://i.ibb.co/SBZt6bC/ASMSHolidays-Mumbai-Elephanta-Caves02.jpg',
    },
    {
      name: 'Juhu Beach',
      description: 'Spend a relaxing day at Juhu Beach, one of Mumbai\'s most popular beaches. Enjoy the sandy shores, indulge in local street food, and experience the vibrant atmosphere.',
      image: 'https://i.ibb.co/HFJYcX2/ASMSHolidays-Mumbai-Juhu-Beach03.jpg',
    },
    {
      name: 'Bollywood Studio Tour',
      description: 'Go behind the scenes of Bollywood with a studio tour, where you can witness the magic of Indian cinema, see film sets, and learn about the filmmaking process.',
      image: 'https://i.ibb.co/kQ2qjDX/ASMSHolidays-Mumbai-Bollywood-Tour.jpg',
    },
    {
      name: 'Chhatrapati Shivaji Terminus',
      description: 'Visit the historic Chhatrapati Shivaji Terminus, a UNESCO World Heritage Site and one of Mumbai\'s architectural gems. Admire the stunning Victorian Gothic style of the train station.',
      image: 'https://i.ibb.co/j6K83VY/ASMSHolidays-Mumbai-Chhatrapati-Shivaji-Maharaj-Terminus02.jpg',
    },
    {
      name: 'Colaba Causeway',
      description: 'Explore the bustling Colaba Causeway, a popular street market in Mumbai. Shop for souvenirs, clothes, accessories, and indulge in local street food.',
      image: 'https://i.ibb.co/tHxfTN5/ASMSHolidays-Mumbai-Colaba-Causeway.jpg',
    },
  ];

  const activitiesInGoa = [
    {
      name: 'Visit the Beaches',
      description: 'Explore the beautiful beaches of Goa, such as Baga Beach, Calangute Beach, and Anjuna Beach. Relax on the golden sands, take a dip in the Arabian Sea, and enjoy water sports and beachside activities.',
      image: 'https://i.ibb.co/Cn9Zcj8/ASMSHolidays-Goa-Beaches.webp',
    },
    {
      name: 'Dudhsagar Waterfalls',
      description: 'Experience the majestic Dudhsagar Waterfalls, one of India\'s tallest waterfalls. Take a trek through the lush green forests and witness the cascading white waters amidst the picturesque surroundings.',
      image: 'https://i.ibb.co/7ztc1z3/ASMSHolidays-goa-Dudhsagar-Falls-The-sea-of-Milk.jpg',
    },
    {
      name: 'Water Sports',
      description: 'Indulge in thrilling water sports activities in Goa, including jet skiing, parasailing, banana boat rides, and scuba diving. Experience the adrenaline rush and enjoy the vibrant marine life and coral reefs.',
      image: 'https://i.ibb.co/TbYYSb9/ASMSHolidays-Goa-Water-Sports0.webp',
    },
    {
      name: 'Night Markets',
      description: 'Explore the vibrant night markets of Goa, such as Anjuna Flea Market and Saturday Night Market. Shop for unique handicrafts, clothes, accessories, and enjoy live music, performances, and delicious street food.',
      image: 'https://i.ibb.co/2FjrSLP/ASMSHoliday-Goa-Night-Market.webp',
    },
    {
      name: 'Fort Aguada',
      description: 'Visit Fort Aguada, a 17th-century Portuguese fort located in North Goa. Explore the fortification, enjoy panoramic views of the Arabian Sea, and learn about its historical significance.',
      image: 'https://i.ibb.co/T8xRXPp/ASMSHolidays-fort-aguada.jpg',
    },
    {
      name: 'Cruise on the Mandovi River',
      description: 'Take a cruise on the Mandovi River and enjoy the scenic beauty of Goa\'s backwaters. Experience live music, dance performances, and savor Goan cuisine while cruising along the tranquil river.',
      image: 'https://i.ibb.co/BLz00TS/ASMSHolidays-Goa-Cruise.webp',
    },
    {
      name: 'Spice Plantations',
      description: 'Visit spice plantations in Goa and take a guided tour to learn about various spices and their cultivation. Experience the aromatic scents, taste traditional Goan cuisine, and enjoy a relaxing nature walk.',
      image: 'https://i.ibb.co/Y7MDwmn/ASMSHolidays-goa-Spice-Plantations.jpg',
    },
    {
      name: 'Dolphin Spotting',
      description: 'Embark on a dolphin spotting tour and witness these playful creatures in their natural habitat. Enjoy a boat ride along the Goan coastline and be mesmerized by the sight of dolphins leaping and swimming alongside.',
      image: 'https://i.ibb.co/H4YnfSS/ASMSHolidays-Goa-Dolphin-Spotting01.jpg',
    },
    {
      name: 'Casinos',
      description: 'Experience the vibrant nightlife of Goa by visiting the casinos. Test your luck, enjoy live entertainment, and indulge in fine dining and drinks at the floating casinos on the Mandovi River.',
      image: 'https://i.ibb.co/d7cwW2v/ASMSHolidays-Casinos-in-Goa.jpg',
    },
  ];

  const activitiesInRajasthan = [
    {
      name: 'Visit Jaipur City Palace',
      description: 'Explore the magnificent City Palace in Jaipur, which is a perfect blend of Rajasthani and Mughal architecture. Admire the intricate details, beautiful courtyards, and stunning artifacts.',
      image: 'https://i.ibb.co/SrW9R3k/ASMSHolidays-rajasthan-City-Palace-Jaipur.jpg',
    },
    {
      name: 'Explore Mehrangarh Fort',
      description: 'Visit Mehrangarh Fort in Jodhpur, one of the largest forts in India. Explore its impressive architecture, intricate carvings, and enjoy panoramic views of the Blue City.',
      image: 'https://i.ibb.co/xX1bCWp/ASMSHolidays-Mehrangarh-Fort.webp',
    },
    {
      name: 'Udaipur Lake Pichola Boat Ride',
      description: 'Take a boat ride on Lake Pichola in Udaipur and witness the serene beauty of the lake and its surrounding palaces and hills. Enjoy the romantic ambiance and breathtaking views.',
      image: 'https://i.ibb.co/P9sKjRV/ASMSHolidays-Rajasthan-Udaipur-boatride.jpg',
    },
    {
      name: 'Ranthambore National Park Safari',
      description: 'Embark on a wildlife safari in Ranthambore National Park, known for its tiger population. Explore the diverse flora and fauna, and have a chance to spot tigers, leopards, and other wildlife species.',
      image: 'https://i.ibb.co/Jj6GCfQ/ASMSHolidays-Rajasthan-Ranthambore-National-Park03.jpg',
    },
    {
      name: 'Visit the Hawa Mahal',
      description: 'Marvel at the iconic Hawa Mahal in Jaipur, also known as the Palace of Winds. Admire its unique architecture and intricate latticework, and learn about its historical significance.',
      image: 'https://i.ibb.co/xLkc5bd/ASMSHolidays-Rajasthan-Hawa-Mahal.jpg',
    },
    {
      name: 'Pushkar Camel Fair',
      description: 'Experience the vibrant Pushkar Camel Fair, a world-famous event held annually in Pushkar. Witness the camel trading, cultural performances, and participate in religious rituals.',
      image: 'https://i.ibb.co/3C9s1py/ASMSHolidays-Rajasthan-Pushkar-Camel-Fair01.jpg',
    },
    {
      name: 'Jaisalmer Fort',
      description: 'Explore the magnificent Jaisalmer Fort, also known as the Golden Fort, a UNESCO World Heritage Site. Wander through its narrow lanes, visit Jain temples, and enjoy panoramic views of the city.',
      image: 'https://i.ibb.co/0Z26kxf/ASMSHolidays-Rajasthan-Jaisalmer-Fort01.webp',
    },
    {
      name: 'Chittorgarh Fort',
      description: 'Visit the majestic Chittorgarh Fort, one of the largest forts in India and a UNESCO World Heritage Site. Explore its palaces, temples, and historical monuments, and learn about Rajput history.',
      image: 'https://i.ibb.co/TcxRRZM/ASMSHolidays-Rajasthan-Chittorgarh-Fort.jpg',
    },
    {
      name: 'Jaipur Elephant Ride at Amer Fort',
      description: 'Take an elephant ride to Amer Fort in Jaipur and experience the royal treatment. Enjoy the regal ride up the hill and explore the grandeur of Amer Fort.',
      image: 'https://i.ibb.co/xLVTd7k/ASMSholidays-rajasthan-Elephant-Safari-Amber-Fort-Jaipur.jpg',
    },
  ];

  const activitiesInKerala = [
    {
      name: 'Backwater Houseboat Cruise',
      description: 'Experience the serene backwaters of Kerala by taking a houseboat cruise. Float along the tranquil canals, witness the lush green landscapes, and indulge in local cuisine.',
      image: 'https://i.ibb.co/M1wztL7/ASMSHolidays-backwater-houseboat-scaled.jpg',
    },
    {
      name: 'Kathakali Performance',
      description: 'Witness a traditional Kathakali dance performance, a classical dance form of Kerala. Enjoy the vibrant costumes, elaborate makeup, and the expressive storytelling through dance and music.',
      image: 'https://i.ibb.co/X4sB64j/ASMSHolidays-kerala-Kathakali.jpg',
    },
    {
      name: 'Explore Munnar Tea Gardens',
      description: 'Visit the picturesque tea gardens in Munnar and witness the sprawling green tea plantations. Learn about tea processing, enjoy panoramic views, and taste freshly brewed tea.',
      image: 'https://i.ibb.co/TtNMLz5/ASMSHolidays-kerala-munnar-tea-garden.jpg',
    },
    {
      name: 'Kerala Ayurvedic Massage',
      description: 'Indulge in a rejuvenating Ayurvedic massage, a traditional form of therapy in Kerala. Experience relaxation, healing, and the ancient knowledge of Ayurveda.',
      image: 'https://i.ibb.co/HFysVws/ASMSHolidays-kerala-Ayurvedic-Massage.jpg',
    },
    {
      name: 'Periyar Wildlife Sanctuary',
      description: 'Explore the Periyar Wildlife Sanctuary in Thekkady, known for its diverse flora and fauna. Take a boat ride on Periyar Lake and have a chance to spot elephants, tigers, and other wildlife.',
      image: 'https://i.ibb.co/Xs2k3Vq/ASMSHolidays-Kerala-Periyar-Wildlife-Sanctuary01.jpg',
    },
    {
      name: 'Kerala Backwater Kayaking',
      description: 'Embark on a kayaking adventure in the Kerala backwaters, paddling through narrow canals, lush greenery, and rural villages. Experience the tranquility and beauty of the backwaters up close.',
      image: 'https://i.ibb.co/L5zRDQW/ASMSHolidays-Kerala-Backwater-Kayaking01.jpg',
    },
    {
      name: 'Visit the Athirapally Waterfalls',
      description: 'Witness the majestic Athirapally Waterfalls, one of the largest waterfalls in Kerala. Enjoy the breathtaking views, take a nature walk, and experience the lush tropical surroundings.',
      image: 'https://i.ibb.co/Dg499rY/ASMSHolidays-kerala-athirappilly-Water-Fall.jpg',
    },
    {
      name: 'Explore Fort Kochi',
      description: 'Visit the historic Fort Kochi, known for its colonial architecture, Chinese fishing nets, and vibrant art scene. Explore the narrow streets, visit ancient churches, and enjoy the blend of cultures.',
      image: 'https://i.ibb.co/5RgFHFD/Asms-Holidays-kerala-Fort-Kochi.jpg',
    },
    {
      name: 'Kerala Houseboat Stay',
      description: 'Experience the unique stay in a traditional Kerala houseboat, also known as a Kettuvallam. Enjoy the comfort and tranquility as you glide through the backwaters, surrounded by nature.',
      image: 'https://i.ibb.co/tCCff2n/ASMSHolidays-Kerala-Houseboat-stay.webp',
    },
    {
      name: 'Visit the Periyar Spice Plantations',
      description: 'Explore the aromatic spice plantations in Periyar and learn about the cultivation and processing of various spices. Experience the scents, flavors, and traditional farming practices.',
      image: 'https://i.ibb.co/sy2x6Mr/ASMSHolidays-kerala-Spice-tea-garden.jpg',
    },
  ];



const bestPlacesToVisit1 = [
    {
      name: "Radhanagar Beach",
      description:
        "Radhanagar Beach is known as one of the most beautiful beaches in Asia. It attracts huge tourism due to its awe striking beauty and is also titled as the 7th most beautiful beach in the world. Known for its pristine beauty, Radhanagar Beach on Havelock Island is often rated as one of the best beaches in Asia. Its white sandy shores and clear turquoise waters make it perfect for swimming and sunbathing.",
      image:
        "https://i.ibb.co/LxhxwB7/ASMSHolidays-Anadaman-Radhanagar-Beach01.jpg",
    },
    {
      name: "Cellular Jail",
      description:
        "Located in Port Blair, the Cellular Jail is a historic site that played a significant role during India's struggle for independence. Visit the jail to learn about its history and the sacrifices made by freedom fighters.",
      image:
        "https://i.ibb.co/wQMVv8W/ASMSHolidays-Andaman-Cellular-Jail01.jpg",
    },
    {
      name: "Ross Island",
      description:
        "Once the administrative headquarters of the British in the Andaman Islands, Ross Island is now an uninhabited island with remnants of colonial buildings and ruins. Explore the island and witness its intriguing past.",
      image:
        "https://i.ibb.co/bvpGwQQ/ASMSHolidays-Andaman-Ross-Island01.jpg",
    },
    {
      name: "North Bay Island",
      description:
        "A popular destination for water sports enthusiasts, North Bay Island offers activities like snorkeling and scuba diving. Discover the vibrant coral reefs and diverse marine life that the island is known for.",
      image:
        "https://i.ibb.co/F0ZNL1w/ASMSHolidays-anadaman-north-bay-island.jpg",
    },
    {
      name: "Baratang Island",
      description:
        "Known for its unique natural attractions, Baratang Island is home to limestone caves and mud volcanoes. Take a boat ride through dense mangroves and explore these fascinating geological formations.",
      image:
        "https://i.ibb.co/J2r8jT7/ASMSHolidays-Andaman-Bartang-Island01.jpg",
    },
    {
      name: "Neil Island",
      description:
        "With its tranquil beaches and relaxed ambiance, Neil Island is perfect for those seeking a peaceful getaway. Enjoy leisurely walks along the shores, swimming, and exploring the underwater world through snorkeling.",
      image:
        "https://i.ibb.co/q04YfRQ/ASMSHolidays-Andaman-Neil-Island01.png",
    },
    {
      name: "Mahatma Gandhi Marine National Park",
      description:
        "Located near Wandoor Beach, this national park is a haven for marine biodiversity. Take a glass-bottom boat ride or go snorkeling to witness the colorful coral reefs and diverse marine species.",
      image:
        "https://i.ibb.co/64PnC19/ASMSHolidays-Andaman-Mahatma-Gandhi-Marine-National-Park01.jpg",
    },
    {
      name: "Limestone Caves, Diglipur",
      description:
        "Situated in Diglipur, the limestone caves are a natural wonder with unique formations. Explore the caves and marvel at the stalactites and stalagmites that adorn them.",
      image:
        "https://i.ibb.co/NyfdFwv/ASMSHolidays-Andaman-Limestone-Caves-Diglipur01.jpg",
    },
    {
      name: "Corbyn's Cove Beach",
      description:
        "Located near Port Blair, Corbyn's Cove Beach is a popular spot for relaxation and water activities. Enjoy swimming, sunbathing, and indulging in various water sports.",
      image:
        "https://i.ibb.co/6X1p4P7/ASMSHolidays-Anadaman-Corbyn-s-Cove-Beach.png",
    },
    {
      name: "Mount Harriet National Park",
      description:
        "Situated on Mount Harriet, this national park offers stunning views of the Andaman archipelago. Explore the rich biodiversity, go hiking, and spot exotic flora and fauna.",
      image:
        "https://i.ibb.co/TK4bP9y/ASMSHoildays-Anadaman-Mount-Harriet-National-Park.jpg",
    },
  ]

  const bestPlacesToVisit2 = [
    {
      name: "Mall Road, Shimla",
      description:
        "Mall Road is the main street in Shimla and a popular tourist attraction. It offers a range of shops, restaurants, and cafes, where you can indulge in shopping and try local delicacies.",
      image:
        "https://i.ibb.co/F4hGRpb/ASMSHolidays-Shimla-Manali-Mall-Road01.jpg",
    },
    {
      name: "Kufri",
      description:
        "Kufri is a small hill station near Shimla known for its panoramic views and adventure activities. You can enjoy horse riding, skiing, and visit the Himalayan Nature Park to see various species of wildlife.",
      image:
        "https://i.ibb.co/s2HyN5L/ASMSHolidays-Shimla-Manali-Kufri01.jpg",
    },
    {
      name: "The Ridge, Shimla",
      description:
        "The Ridge is a large open space located in the heart of Shimla. It offers breathtaking views of the surrounding mountains and serves as a venue for cultural events and festivals.",
      image:
        "https://i.ibb.co/B4p5sVP/ASMSHolidays-Shimla-Manali-The-Ridge01.jpg",
    },
    {
      name: "Chadwick Falls, Shimla",
      description:
        "Chadwick Falls is a beautiful waterfall located near Shimla. It is surrounded by lush greenery and offers a serene ambiance for visitors to relax and enjoy the natural beauty.",
      image:
        "https://i.ibb.co/ySrMWN3/ASMSHolidays-Shimla-Manali-Chadwick-Falls1.webp",
    },
    {
      name: "Rohtang Pass, Manali",
      description:
        "Rohtang Pass is a high mountain pass located in the Pir Panjal Range of the Himalayas. It offers stunning views of snow-capped peaks, glaciers, and picturesque landscapes. It is a popular destination for adventure enthusiasts and nature lovers.",
      image:
        "https://i.ibb.co/1TNysPc/ASMSHolidays-Shimal-manali-Rohtang-pass.jpg",
    },
    {
      name: "Hadimba Temple, Manali",
      description:
        "Hadimba Temple is a wooden temple dedicated to Goddess Hadimba, located in Manali. It is known for its unique architecture and tranquil surroundings. The temple is surrounded by tall deodar trees and offers a peaceful atmosphere for devotees and tourists.",
      image:
        "https://i.ibb.co/HTr4q2q/ASMSHolidays-Shimla-Manali-Hadimba-Temple01.jpg",
    },
    {
      name: "Solang Valley, Manali",
      description:
        "Solang Valley is a picturesque valley near Manali, known for its stunning landscapes and adventure activities. It offers opportunities for activities like paragliding, zorbing, skiing, and snowboarding. The valley is surrounded by snow-capped mountains, lush greenery, and a gushing river, making it a perfect spot for nature lovers and adventure seekers.",
      image:
        "https://i.ibb.co/GH7RySz/ASMSHolidays-Shimla-Manali-Solang-Valley01.jpg",
    },
    {
      name: "Jakhu Temple, Shimla",
      description:
        "Jakhu Temple is an ancient temple dedicated to Lord Hanuman, located on Jakhu Hill in Shimla. It offers panoramic views of the city and the surrounding mountains. The temple is known for its large statue of Lord Hanuman and attracts devotees and tourists alike.",
      image:
        "https://i.ibb.co/58m18Gg/ASMSHolidays-Shimla-Manali-Jakhu-Temple01.jpg",
    },
    {
      name: "Manu Temple, Manali",
      description:
        "Manu Temple is a revered temple dedicated to Sage Manu, considered the creator of the human race in Hindu mythology. It is located in Old Manali and offers a peaceful and spiritual ambiance. The temple is surrounded by lush green forests and provides beautiful views of the Beas River flowing nearby.",
      image:
        "https://i.ibb.co/DCyxfJf/ASMSHolidays-Shimla-Manali-Manu-Temple01.jpg",
    },
    {
      name: "Tara Devi Temple, Shimla",
      description:
        "Tara Devi Temple is a sacred Hindu temple located on Tara Parvat near Shimla. It is dedicated to Goddess Tara, a form of Goddess Durga. The temple offers panoramic views of the surrounding mountains and valleys, making it a peaceful and spiritual place for devotees and visitors.",
      image:
        "https://i.ibb.co/1XTBNkt/ASMSHolidays-Shimla-Manali-Tara-Devi-Temple01.jpg",
    },
    {
      name: "Beas Kund, Manali",
      description:
        "Beas Kund is a sacred lake located in the Pir Panjal Range of the Himalayas, near Manali. It is believed to be the source of the Beas River and holds great significance in Hindu mythology. The trek to Beas Kund offers breathtaking views of the snow-covered peaks and is a popular destination for trekking enthusiasts.",
      image:
        "https://i.ibb.co/MMLqBMx/ASMSHolidays-Shimla-manali-Beas-Kund.jpg",
    },
  ];

  const bestPlacesToVisit3 = [
    {
      name: "Dal Lake",
      description:
        "Dal Lake is a famous lake in Srinagar, known for its scenic beauty and shikara rides. You can enjoy a peaceful boat ride on the lake and explore the floating gardens and houseboats.",
      image:
        "https://i.ibb.co/TPgHNk6/ASMSHolidays-kashmir-dallake.jpg",
    },
    {
      name: "Gulmarg",
      description:
        "Gulmarg is a popular hill station and ski destination in Kashmir. It is surrounded by snow-capped mountains and offers breathtaking views. You can enjoy skiing, cable car rides, and trekking in Gulmarg.",
      image:
        "https://i.ibb.co/6PQxtsK/ASMSHolidays-Kashmir-Gulmarg01.jpg",
    },
    {
      name: "Pahalgam",
      description:
        "Pahalgam is a picturesque town located in the Anantnag district of Kashmir. It is known for its stunning valleys, meadows, and the Lidder River. Pahalgam is a popular base for trekking and pilgrimage to Amarnath Cave.",
      image:
        "https://i.ibb.co/DGbv2jH/ASMSHolidays-kashmir-Pahalgam.webp",
    },
    {
      name: "Sonamarg",
      description:
        "Sonamarg, also known as the 'Meadow of Gold,' is a breathtaking hill station in Kashmir. It is famous for its alpine meadows, snow-capped mountains, and beautiful lakes. You can enjoy activities like trekking and camping in Sonamarg.",
      image:
        "https://i.ibb.co/Yknj20b/ASMSHolidays-Kashmir-Sonamarg01.jpg",
    },
    {
      name: "Shalimar Bagh",
      description:
        "Shalimar Bagh is a stunning Mughal garden located in Srinagar. It is known for its terraced lawns, fountains, and beautiful flowerbeds. The garden offers a serene and relaxing atmosphere for visitors.",
      image:
        "https://i.ibb.co/Qc4BYGS/ASMSHolidays-Shalimar-garden.jpg",
    },
    {
      name: "Nishat Bagh",
      description:
        "Nishat Bagh is another famous Mughal garden situated in Srinagar. It is known for its well-manicured lawns, colorful flowerbeds, and stunning views of Dal Lake. The garden is a perfect spot for a leisurely stroll.",
      image:
        "https://i.ibb.co/JmVVRcW/ASMSHolidays-nishat-garden.jpg",
    },
    {
      name: "Aru Valley",
      description:
        "Aru Valley is a scenic valley located in Pahalgam. It is surrounded by snow-capped mountains, lush meadows, and gushing streams. Aru Valley is a popular destination for nature lovers and trekkers.",
      image:
        "https://i.ibb.co/27St7vq/ASMSHolidays-Kashmir-Aru-Valley01.jpg",
    },
    {
      name: "Betaab Valley",
      description:
        "Betaab Valley is a picturesque valley located near Pahalgam. It is named after the Bollywood movie 'Betaab' that was shot here. The valley is known for its lush greenery, crystal-clear streams, and snow-capped mountains.",
      image:
        "https://i.ibb.co/GVKgBBK/ASMSHo-LIDAYS-KASHMIR-Betaab-Valley01.jpg",
    },
  ];
  
  const bestPlacesToVisit4 = [
    {
      name: "Bangaram Island",
      description:
        "Bangaram Island is a secluded paradise in Lakshadweep. It is famous for its turquoise lagoons, white sandy beaches, and coral reefs. You can relax on the beach, go snorkeling, or take a boat ride to explore the surrounding islands.",
      image:
        "https://i.ibb.co/2kNk9pj/ASMSHolidays-Lakshadeep-Bangaram-Island01.webp",
    },
    {
      name: "Kavaratti Island",
      description:
        "Kavaratti Island is the administrative capital of Lakshadweep. It offers beautiful beaches, coral reefs, and a vibrant marine ecosystem. You can visit the Kavaratti Aquarium to see a variety of colorful fish and marine species.",
      image:
        "https://i.ibb.co/LtDbpq4/ASMSHolidays-Lakshadeep-Kavaratti-Island.jpg",
    },
    {
      name: "Minicoy Island",
      description:
        "Minicoy Island is the southernmost island in Lakshadweep. It is known for its scenic beauty, pristine beaches, and rich cultural heritage. You can explore the traditional Laccadive Seafood and experience the unique culture of the island.",
      image:
        "https://i.ibb.co/cgGnZZg/ASMSHolidays-Lakshadeep-Minicoy-Island01.jpg",
    },
    {
      name: "Kalpeni Island",
      description:
        "Kalpeni Island is a group of three small islands in Lakshadweep. It is famous for its coral reefs, lagoons, and sandy beaches. You can go snorkeling, kayaking, or take a boat ride to explore the beautiful lagoon.",
      image:
        "https://i.ibb.co/KF6hhxM/ASMSHolidays-Lakshadeep-Kalpeni-Island01.jpg",
    },
    {
      name: "Agatti",
      description:
        "Agatti is a small island in Lakshadweep known for its picturesque beaches and coral reefs. You can indulge in activities like snorkeling, scuba diving, and deep-sea fishing. The island also offers opportunities for water sports like kayaking and sailing.",
      image:
        "https://i.ibb.co/89bdw3z/ASMSHolidays-Lakshadeep-Agatti-Island01.webp",
    },
    {
      name: "Kadmat Island",
      description:
        "Kadmat Island is a popular tourist destination in Lakshadweep. It is known for its long sandy beaches, coral reefs, and clear blue waters. You can enjoy water sports like snorkeling and scuba diving or simply relax on the beach.",
      image:
        "https://i.ibb.co/x2z4yj6/ASMSHolidays-Lakshadeep-Kadmat-Island01.jpg",
    },
    {
      name: "Amini Island",
      description:
        "Amini Island is the second largest island in Lakshadweep. It offers pristine beaches, turquoise lagoons, and coral reefs. You can visit the Amini Village to experience the local culture and try traditional seafood delicacies.",
      image:
        "https://i.ibb.co/HDKY7d9/ASMSHolidays-Lakshadeep-Amini-Island01.webp",
    },
    {
      name: "Andrott Island",
      description:
        "Andrott Island is the largest island in Lakshadweep. It is known for its sandy beaches, coconut groves, and historical sites. You can visit the Buddhist archaeological site, the Juma Mosque, and enjoy water sports like snorkeling and scuba diving.",
      image:
        "https://i.ibb.co/VvVzYGB/ASMSHolidays-Lakshadeep-Androth-Island.jpg",
    },
  ];

  const bestPlacesToVisit5 = [
    {
      name: "Gateway of India",
      description:
        "The Gateway of India is an iconic monument and popular tourist attraction in Mumbai. It is a grand arch built in Indo-Saracenic style, overlooking the Arabian Sea. You can take a stroll, enjoy the sea breeze, and admire the architectural beauty of the monument.",
      image:
        "https://i.ibb.co/6BcCQkK/ASMSHolidays-Mumbai-Gateway-of-India01.webp",
    },
    {
      name: "Marine Drive",
      description:
        "Marine Drive is a scenic promenade in South Mumbai, also known as the 'Queen's Necklace.' It is a 3.6-kilometer-long road along the Arabian Sea, offering breathtaking views and a vibrant atmosphere. You can take a leisurely walk, enjoy street food, or simply relax and enjoy the sunset.",
      image:
        "https://i.ibb.co/RQwgB4K/ASMSHolidays-Mumbai-Marine-Drive01.webp",
    },
    {
      name: "Colaba Causeway",
      description:
        "Colaba Causeway is a bustling street market in South Mumbai. It is famous for its shops, boutiques, and street stalls selling a variety of items like clothing, accessories, handicrafts, and antiques. You can shop for souvenirs, enjoy street food, and experience the vibrant atmosphere of the market.",
      image:
        "https://i.ibb.co/tHxfTN5/ASMSHolidays-Mumbai-Colaba-Causeway.jpg",
    },
    {
      name: "Elephanta Caves",
      description:
        "Elephanta Caves is a UNESCO World Heritage Site located on Elephanta Island, just a ferry ride away from Mumbai. The caves feature intricately carved rock-cut temples dedicated to Lord Shiva. You can explore the caves, admire the sculptures, and learn about the rich history and mythology associated with them.",
      image:
        "https://i.ibb.co/CBbfF2q/ASMSHolidays-Mumbai-Elephanta-Caves01.jpg",
    },
    {
      name: "Chhatrapati Shivaji Maharaj Terminus",
      description:
        "Chhatrapati Shivaji Maharaj Terminus, formerly known as Victoria Terminus, is a historic railway station and UNESCO World Heritage Site in Mumbai. It is an architectural marvel, blending Victorian Gothic and Indian architectural styles. You can admire the stunning architecture and learn about the city's railway heritage.",
      image:
        "https://i.ibb.co/Z2XdB9z/ASMSHolidays-Mumbai-Chhatrapati-Shivaji-Maharaj-Terminus01.jpg",
    },
    {
      name: "Juhu Beach",
      description:
        "Juhu Beach is a popular beach in Mumbai, known for its lively atmosphere and scenic beauty. It offers a long stretch of sandy shore, where you can take a leisurely walk, enjoy street food, or indulge in recreational activities. Juhu Beach is also famous for its vibrant nightlife and celebrity sightings.",
      image:
        "https://i.ibb.co/vJjnrjH/ASMSHolidays-Mumbai-Juhu-Beach01.jpg",
    },
    {
      name: "Haji Ali Dargah",
      description:
        "Haji Ali Dargah is a prominent Muslim pilgrimage site and a stunning architectural masterpiece in Mumbai. It is a mosque and tomb situated on a small islet in the Arabian Sea. You can visit the dargah, offer prayers, and admire the exquisite Indo-Islamic architecture.",
      image:
        "https://i.ibb.co/QDDdcWg/image.png",
    },
    {
      name: "Siddhivinayak Temple",
      description:
        "Siddhivinayak Temple is a famous Hindu temple dedicated to Lord Ganesha in Mumbai. It is one of the most visited temples in the city, known for its spiritual significance and beautiful architecture. You can seek blessings, attend religious ceremonies, and experience the devotional ambiance of the temple.",
      image:
        "https://i.ibb.co/DYSBJMX/ASMSHolidays-Mumbai-Siddhivinayak.jpg",
    },
    {
      name: "Sanjay Gandhi National Park",
      description:
        "Sanjay Gandhi National Park, also known as Borivali National Park, is a sprawling wildlife sanctuary within the city limits of Mumbai. It is home to diverse flora and fauna, including leopards, deer, and numerous bird species. You can go for nature walks, visit the Kanheri Caves, or enjoy a safari in the park.",
      image:
        "https://i.ibb.co/fHGYQq8/ASMSHolidays-mumbai-sanjay-gandhi-national-park.jpg",
    },
    {
      name: "Nehru Science Centre",
      description:
        "Nehru Science Centre is a science museum and educational center in Mumbai. It offers interactive exhibits, science shows, and demonstrations that make learning fun and engaging. You can explore various scientific concepts, participate in hands-on activities, and expand your knowledge in an interactive environment.",
      image:
        "https://i.ibb.co/sV3crRg/ASMSHolidays-Mumbai-Nehru-Science-Center01.jpg",
    },
  ];

  const bestPlacesToVisit6 = [
    {
      name: "Calangute Beach",
      description:
        "Calangute Beach is one of the most popular and largest beaches in Goa. It offers a vibrant atmosphere, golden sands, and a range of water sports activities. You can relax on the beach, take a dip in the sea, or indulge in thrilling activities like parasailing and jet skiing.",
      image:
        "https://i.ibb.co/xGHc3h1/ASMSHolidays-Goa-Calangute-Beach01.jpg",
    },
    {
      name: "Baga Beach",
      description:
        "Baga Beach is another famous beach in Goa, known for its lively nightlife and water sports. It offers a wide range of beach shacks, restaurants, and clubs. You can enjoy water sports like banana boat rides and parasailing or simply relax and soak up the sun.",
      image:
        "https://i.ibb.co/bdp8csg/ASMSHolidays-Goa-baga-beach.jpg",
    },
    {
      name: "Dudhsagar Falls",
      description:
        "Dudhsagar Falls is a majestic waterfall located on the Mandovi River. It is one of the tallest waterfalls in India and offers a breathtaking sight. You can take a jeep safari or trek through the scenic trails to reach the waterfall and enjoy its beauty.",
      image:
        "https://i.ibb.co/XpJd1Mk/ASMSHolidays-Goa-Dudhsagar-Falls01.jpg",
    },
    {
      name: "Fort Aguada",
      description:
        "Fort Aguada is a 17th-century Portuguese fort located in North Goa. It is a well-preserved fort and offers panoramic views of the Arabian Sea. You can explore the fort's architecture, visit the lighthouse, and enjoy the scenic surroundings.",
      image:
        "https://i.ibb.co/PFGyQ1y/ASMS-Holidays-Goa-Fort-aguada.jpg",
    },
    {
      name: "Anjuna Beach",
      description:
        "Anjuna Beach is known for its laid-back and hippie vibe. It is famous for its weekly flea market, where you can find a variety of items like clothes, jewelry, handicrafts, and souvenirs. You can relax on the beach, enjoy live music at beach shacks, or attend beach parties.",
      image:
        "https://i.ibb.co/SfyzgyJ/ASMSHolidays-anjuna-beach.webp",
    },
    {
      name: "Palolem Beach",
      description:
        "Palolem Beach is a scenic beach located in South Goa. It is known for its natural beauty, calm waters, and picturesque surroundings. You can relax on the beach, go for a swim, or take a boat trip to explore the nearby islands and spot dolphins.",
      image:
        "https://i.ibb.co/rFg5Ysc/ASMSHolidays-Goa-palolem-beach.jpg",
    },
    {
      name: "Chapora Fort",
      description:
        "Chapora Fort is a popular tourist attraction in Goa, known for its panoramic views and historical significance. It gained fame after being featured in the Bollywood movie 'Dil Chahta Hai.' You can explore the fort, enjoy the scenic views, and capture memorable photographs.",
      image:
        "https://i.ibb.co/T4Mgqwq/ASMSHolidays-Goa-Chapora-fort.jpg",
    },
    {
      name: "Candolim Beach",
      description:
        "Candolim Beach is a serene and less crowded beach in North Goa. It offers a tranquilatmosphere, soft sands, and clear waters. You can enjoy long walks along the beach, relax under the shade of beach umbrellas, or try water sports like jet skiing and parasailing.",
      image:
        "https://i.ibb.co/bXVLhnd/ASMSHolidays-Goa-Candolim-Beach01.jpg",
    },
    {
      name: "Mangueshi Temple",
      description:
        "Mangueshi Temple is a famous Hindu temple dedicated to Lord Shiva. It is located in Ponda, Goa, and is known for its exquisite architecture and religious significance. You can visit the temple, attend prayers, and explore the intricate carvings and sculptures.",
      image:
        "https://i.ibb.co/Xt0d415/ASMSHolidays-Goa-mangesh-temple.jpg",
    },
    {
      name: "Fontainhas",
      description:
        "Fontainhas is the Latin Quarter of Panaji, the capital city of Goa. It is known for its colorful Portuguese-style houses, narrow streets, and vibrant atmosphere. You can take a leisurely walk through the neighborhood, admire the architecture, and visit art galleries and quaint cafes.",
      image:
        "https://i.ibb.co/kqg46DC/ASMSHolidays-Goa-Fontainhas.jpg",
    },
  ];

  const bestPlacesToVisit7 = [
    {
      name: "Jaipur",
      description:
        "Jaipur, also known as the 'Pink City,' is the capital of Rajasthan. It is famous for its magnificent palaces, forts, and vibrant markets. You can visit attractions like the Amber Fort, City Palace, Hawa Mahal, and indulge in shopping for handicrafts, textiles, and gemstones.",
      image:
        "https://i.ibb.co/GTWCSpk/ASMSHolidays-Rajasthan-Jaipur01.jpg",
    },
    {
      name: "Udaipur",
      description:
        "Udaipur is known as the 'City of Lakes' and is famous for its beautiful lakes, palaces, and romantic ambiance. You can visit attractions like the Lake Palace, City Palace, Jag Mandir, and enjoy boat rides on Lake Pichola. Udaipur is also known for its intricate handicrafts and traditional Rajasthani cuisine.",
      image:
        "https://i.ibb.co/sWQ85nD/ASMSHolidays-Rajasthan-UDAIPUR01.jpg",
    },
    {
      name: "Jaisalmer",
      description:
        "Jaisalmer is a desert city located in the Thar Desert of Rajasthan. It is known for its magnificent sand dunes, stunning forts, and intricately carved havelis. You can visit attractions like Jaisalmer Fort, Patwon Ki Haveli, and enjoy camel safaris and cultural performances in the desert.",
      image:
        "https://i.ibb.co/NCxZ8w8/ASMSHolidays-Rajasthan-Jaisalmer-01.jpg",
    },
    {
      name: "Jodhpur",
      description:
        "Jodhpur is known as the 'Blue City' and is famous for its majestic forts and palaces. You can visit attractions like Mehrangarh Fort, Jaswant Thada, and explore the narrow lanes of the blue-painted houses in the old city. Jodhpur is also known for its vibrant textiles and handicrafts.",
      image:
        "https://i.ibb.co/6B0H5N4/ASMSHolidays-Rajasthan-Jodhpur01.jpg",
    },
    {
      name: "Pushkar",
      description:
        "Pushkar is a sacred town famous for its holy lake and the Brahma Temple. It is a major pilgrimage site and is known for hosting the Pushkar Camel Fair. You can visit the temples, take a dip in the holy lake, and experience the spiritual and cultural charm of the town.",
      image:
        "https://i.ibb.co/jTvP28w/ASMSHolidays-Rajasthan-Pushkar01.jpg",
    },
    {
      name: "Ranthambore National Park",
      description:
        "Ranthambore National Park is a renowned wildlife sanctuary in Rajasthan. It is known for its population of royal Bengal tigers and offers excellent opportunities for tiger spotting. You can enjoy thrilling jungle safaris and witness diverse flora and fauna in their natural habitat.",
      image:
        "https://i.ibb.co/k0RSHPr/ASMSHolidays-Rajasthan-Ranthambore-National-Park01.jpg",
    },
    {
      name: "Ajmer",
      description:
        "Ajmer is a city known for its religious significance and the Ajmer Sharif Dargah, a Sufi shrine. It is visited by devotees from different faiths. You can visit the Dargah, explore the forts and palaces, and take a trip to the nearby Pushkar.",
      image:
        "https://i.ibb.co/hcdKCZK/ASMSHolidays-Rajasthan-Ajmer02.webp",
    },
    {
      name: "Bikaner",
      description:
        "Bikaner is a city famous for its well-preserved forts, palaces, and camel breeding farms. You can visit attractions like Junagarh Fort, Karni Mata Temple (also known as the Rat Temple), and experience camel safaris in the desert. Bikaner is also known for its delectable sweets and snacks.",
      image:
        "https://i.ibb.co/5R52W71/ASMSHolidays-Rajasthan-Bikaner01.webp",
    },
    {
      name: "Chittorgarh",
      description:
        "Chittorgarh is known for its massive Chittorgarh Fort, which is a UNESCO World Heritage Site. It is one of the largest forts in India and is known for its historical significance and architectural marvels. You can explore the fort, visit the temples, and learn about the heroic tales of Rajput bravery.",
      image:
        "https://i.ibb.co/9yb1vMb/ASMSHolidays-Rajasthan-Chittorgarh01.jpg",
    },
    {
      name: "Mount Abu",
      description:
        "Mount Abu is the only hill station in Rajasthan, located in the Aravalli Range. It offers a pleasant climate, lush greenery, and serene surroundings. You can visit attractions like Dilwara Temples, Nakki Lake, and enjoy panoramic views from the Sunset Point. Mount Abu is also known for its spiritual and cultural significance.",
      image:
        "https://i.ibb.co/PxrZWTz/ASMSHolidays-Rajasthan-Mount-Abu.jpg",
    },
  ];

  
const bestPlacesToVisit8 = [
  {
    name: "Munnar",
    description:
      "Munnar is a picturesque hill station known for its tea plantations, misty mountains, and beautiful landscapes. You can visit attractions like the Eravikulam National Park, Mattupetty Dam, and enjoy scenic walks amidst the tea gardens. Munnar is also a great place for adventure activities like trekking and paragliding.",
    image:
      "https://i.ibb.co/jZVsS8H/ASMSHolidays-Kerala-Munnar.jpg",
  },
  {
    name: "Alleppey",
    description:
      "Alleppey, also known as Alappuzha, is famous for its backwaters and houseboat cruises. You can take a leisurely ride in a traditional houseboat through the scenic backwaters, enjoy the tranquility, and experience the local culture. Alleppey is also known for its coir industry and beautiful beaches.",
    image:
      "https://i.ibb.co/DzdFgkz/ASMSHolidays-kerala-alleppey.jpg",
  },
  {
    name: "Kochi",
    description:
      "Kochi, also known as Cochin, is a vibrant city and a major port in Kerala. It is known for its rich history, colonial architecture, and cultural heritage. You can visit attractions like Fort Kochi, Chinese Fishing Nets, Mattancherry Palace, and explore the bustling markets and spice bazaars.",
    image:
      "https://i.ibb.co/QN0twHw/ASMSHolidays-kerala-kochi.jpg",
  },
  {
    name: "Thekkady",
    description:
      "Thekkady is a nature lover's paradise and is famous for the Periyar National Park, which is a tiger reserve. You can enjoy wildlife safaris, take a boat ride in the Periyar Lake, and spot diverse flora and fauna. Thekkady is also known for its spice plantations and elephant rides.",
    image:
      "https://i.ibb.co/gtvSZ8j/ASMSHolidays-kerala-thekkady.jpg",
  },
  {
    name: "Kovalam",
    description:
      "Kovalam is a popular beach destination in Kerala, known for its golden sands and clear blue waters. You can relax on the beach, enjoy water sports like surfing and parasailing, and indulge in rejuvenating Ayurvedic massages. Kovalam also offers beautiful sunset views and beachside dining options.",
    image:
      "https://i.ibb.co/zHmZWVF/ASMSHolidays-Kerala-KOVALAM.jpg",
  },
  {
    name: "Wayanad",
    description:
      "Wayanad is a scenic hill station nestled in the Western Ghats of Kerala. It is known for its lush greenery, mist-covered mountains, and rich biodiversity. You can visit attractions like Chembra Peak, Pookode Lake, and explore the wildlife sanctuaries and spice plantations in the region.",
    image:
      "https://i.ibb.co/HPcp71y/ASMSHolidays-Kerala-Wayanad.jpg",
  },
  {
    name: "Varkala",
    description:
      "Varkala is a coastal town known for its pristine beaches, cliffs, and spiritual significance. You can relax on the stunning Papanasam Beach, take a dip in the mineral-rich waters of the Varkala Beach, and visit the ancient Janardanaswamy Temple. Varkala is also known for its yoga and Ayurvedic retreats.",
   image:
      "https://i.ibb.co/fYLd2JW/ASMSHolidays-kerala-VARKALA.jpg",
  },
  {
    name: "Kumarakom",
    description:
      "Kumarakom is a serene backwater destination located on the banks of Lake Vembanad. It is known for its lush greenery, houseboat cruises, and bird watching. You can explore the Kumarakom Bird Sanctuary, take a boat ride through the backwaters, and enjoy the tranquility of the surroundings.",
    image:
      "https://i.ibb.co/CPT4QgD/ASMSHolidays-Kerala-Kumarakom.webp",
  },
  {
    name: "Thrissur",
    description:
      "Thrissur, also known as the 'Cultural Capital of Kerala,' is known for its rich cultural heritage, festivals, and temples. You can visit attractions like the Vadakkunnathan Temple, Thrissur Palace, and witness the famous Thrissur Pooram festival. Thrissur is also a hub for traditional arts and crafts.",
    image:
      "https://i.ibb.co/27zgvG4/ASMSHolidays-kerala-Thrissur.jpg",
  },
  {
    name: "Poovar",
    description:
      "Poovar is a scenic coastal village located near Thiruvananthapuram. It is known for its golden sand beaches, backwaters, and estuaries. You can enjoy a boat ride through the Neyyar River, relax on the beach, and explore the mangrove forests in the area.",
    image:
      "https://i.ibb.co/WxnS6jT/ASMSHolidays-Kerala-Poovar.jpg",
  },
];

const videoLocations = {
  location1: {

    video: waveVideo,
    heading: 'Andaman Islands',
    subheading: 'Discover the Pristine Beauty of Andaman Islands - Explore Beaches, Coral Reefs, and Adventure',
    subpage: <Andaman bestPlacesToVisit={bestPlacesToVisit1} />,
    subpage1:<ActivityCarousel  activityToDo={activitiesInAndaman}/>,
    subpage2:<AndamanPrices/>,
    subpage3:<Cruise/>,
    subpage4:<Ferries/>

  },
  location2: {
    video: shimlaVideo,
    heading: 'Shimla & Manali',
    subheading: 'Experience the Enchanting Charm of Shimla and Manali - Perfect Hill Station Getaway',
    subpage: <Andaman bestPlacesToVisit={bestPlacesToVisit2}/>,
    subpage1:<ActivityCarousel  activityToDo={activitiesInShimlaManali}/>
  },
  location3: {
    video: kashmirVideo,
    heading: 'Kashmir',
    subheading: 'Unleash Your Adventure in Majestic Kashmir - Explore Mountains, Lakes, and Cultural Delights',
    subpage: <Andaman bestPlacesToVisit={bestPlacesToVisit3}/>,
    subpage1:<ActivityCarousel  activityToDo={activitiesInKashmir}/>
  },
  location4: {
    video: islandVideo,
    heading: 'Lakshadweep',
    subheading: 'Discover Paradise in the Pristine Islands of Lakshadweep - Sun, Sand, and Coral Reefs',
    subpage: <Andaman bestPlacesToVisit={bestPlacesToVisit4}/>,
    subpage1:<ActivityCarousel  activityToDo={activitiesInLakshadweep}/>
  },
  location5: {
    video: mumbaiVideo,
    heading: 'Mumbai',
    subheading: 'Explore the Vibrant City Life in Mumbai - Bollywood, Street Food, and Historical Landmarks',
    subpage: <Andaman bestPlacesToVisit={bestPlacesToVisit5}/>,
    subpage1:<ActivityCarousel  activityToDo={activitiesInMumbai}/>
  },
  location6: {
    video: goaVideo,
    heading: 'Goa',
    subheading: 'Discover the Tropical Bliss of Goa - Beaches, Nightlife, and Portuguese Heritage',
    subpage: <Andaman bestPlacesToVisit={bestPlacesToVisit6}/>,
    subpage1:<ActivityCarousel  activityToDo={activitiesInGoa}/>
  },
  location7: {
    video: rajasthanVideo,
    heading: 'Rajasthan',
    subheading: 'Experience the Rich Heritage of Rajasthan - Palaces, Forts, and Cultural Extravaganza',
    subpage: <Andaman bestPlacesToVisit={bestPlacesToVisit7}/>,
    subpage1:<ActivityCarousel  activityToDo={activitiesInRajasthan}/>

  },
  location8: {
    video: keralaVideo,
    heading: 'Kerala',
    subheading: 'Immerse Yourself in the Serene Beauty of Kerala - Backwaters, Ayurveda, and Tea Plantations',
    subpage: <Andaman bestPlacesToVisit={bestPlacesToVisit8}/>,
    subpage1:<ActivityCarousel activityToDo={activitiesInKerala}/>
  },
  // Add more locations if needed
};




















  export default videoLocations;